import { getHeaders } from 'api'
import { LastWillUpdate } from 'models/LasttWillUpdate'

export const getTestaments = async (): Promise<string[]> => {
	try {
		const response = await window.fetch('/api/files/testaments')
		return await response.json()
	} catch (e) {
		return []
	}
}

export const uploadTestaments = async (
	formData: FormData
): Promise<string[]> => {
	try {
		const response = await window.fetch('/api/files/testaments', {
			method: 'post',
			body: formData,
		})
		return await response.json()
	} catch (e) {
		return []
	}
}

export const deleteTestament = async (filename: string): Promise<string[]> => {
	try {
		const response = await window.fetch(`/api/files/testaments/${filename}`, {
			method: 'delete',
		})
		return await response.json()
	} catch (e) {
		return []
	}
}

export const getLastWillRecipient = async (): Promise<LastWillUpdate> => {
	try {
		const response = await window.fetch('/api/lastWill/recipient', {
			method: 'get',
		})
		return await response.json()
	} catch (e) {
		return LastWillUpdate.create()
	}
}

export const putLastWillRecipient = async (
	update: LastWillUpdate
): Promise<void> => {
	try {
		const response = await window.fetch('/api/lastWill/recipient', {
			method: 'put',
			body: JSON.stringify(update),
			headers: getHeaders(),
		})
		return await response.json()
	} catch (e) {}
}
