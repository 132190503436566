import { MobileMaxWidth } from 'constants/styles'
import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

interface Props {
	children: ReactNode
	white?: boolean
	padding?: boolean
	textCenter?: boolean
	className?: string
	id?: string
}

export const Section = ({
	children,
	white,
	padding = true,
	textCenter,
	className,
	id,
}: Props) => {
	return (
		<Container
			white={white}
			padding={padding}
			textCenter={textCenter}
			className={className}
			id={id}
		>
			<Content>{children}</Content>
		</Container>
	)
}

export const MobileLeftRightPadding = '30px'

interface ContainerProps {
	white?: boolean
	textCenter?: boolean
	padding: boolean
}

const Container = styled.div<ContainerProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	overflow: hidden;
	${({ white, theme }) =>
		white
			? css`
					background: #fff;
			  `
			: css`
					background: ${theme.colors.Air};
			  `}
	${({ textCenter }) =>
		textCenter &&
		css`
			text-align: center;
		`}
	${({ padding }) =>
		padding
			? css`
					padding: 100px 150px;
					@media (max-width: 1280px) {
						padding: 100px 90px;
					}
					@media (max-width: ${MobileMaxWidth}) {
						padding: 50px ${MobileLeftRightPadding};
					}
			  `
			: null}
`

const Content = styled.div`
	width: 100%;
	max-width: 1200px;
`
