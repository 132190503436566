import { Button } from 'components/controls/Button'
import { H1 } from 'components/controls/H1'
import { Responsive } from 'components/controls/Responsive'
import { TextInput } from 'components/controls/TextInput'
import { LandingGrid } from 'components/widgets/LandingGrid'
import { MobileMaxWidth } from 'constants/styles'
import { useForm } from 'form/helpers'
import { useLanguage } from 'languages/hooks'
import { SignupDetails } from 'models/SignupDetails'
import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components/macro'
import { ErrorsOf } from 'validation/ErrorsOf'
import { checkForErrors } from 'validation/helpers'

interface Props {
	form: SignupDetails
	setForm: Dispatch<SetStateAction<SignupDetails>>
	errors: ErrorsOf<SignupDetails>
	signup: () => Promise<void>
	loading: boolean
}

export const SignupFinalize = ({
	form,
	setForm,
	errors,
	signup,
	loading,
}: Props) => {
	const { translations } = useLanguage()
	const propsFor = useForm(form, setForm, errors, 'user')

	var hasErrors = checkForErrors(errors)

	return (
		<>
			<Title>{translations['signupFinalize.title']}</Title>
			<LandingGrid>
				<Description>
					<Hint>{translations['signupFinalize.features']}</Hint>
				</Description>
				<TextInput
					{...propsFor({
						id: 'password',
						placeholder: 'Letters and numbers',
					})}
					type="password"
					autoComplete="new-password"
					required
					gray
				/>
				<Responsive desktop={<div />} />
				<RepeatPwdInput
					{...propsFor({
						id: 'repeatedPassword',
						placeholder: 'Repeat password above',
					})}
					type="password"
					autoComplete="new-password"
					required
					gray
				/>
				<SignupButton
					text={translations['general.submit']}
					onClick={signup}
					loading={loading}
					small
					primary
				/>
				{hasErrors && (
					<ErrorIndicator>
						{translations['validation.hasErrors']}
					</ErrorIndicator>
				)}
			</LandingGrid>
		</>
	)
}

const Title = styled(H1)`
	margin-bottom: 2em;
`

const Description = styled.div`
	grid-row: span 2;
	grid-column: span 2;
	font-size: 24px;
	line-height: 40px;
	color: ${({ theme }) => theme.colors.Stone};
	@media (max-width: ${MobileMaxWidth}) {
		grid-row: auto;
		grid-column: auto;
		font-size: 17px;
		line-height: 28px;
		text-align: center;
	}
`

const Hint = styled.div`
	font-size: 18px;
	line-height: 32px;
	@media (max-width: ${MobileMaxWidth}) {
		font-size: 15px;
		line-height: 26px;
	}
`

const RepeatPwdInput = styled(TextInput)`
	align-self: end;
`

const ErrorIndicator = styled.div`
	grid-column: span 4;
	justify-self: end;
	line-height: 45px;
	color: ${({ theme }) => theme.colors.Error};
	@media (max-width: ${MobileMaxWidth}) {
		order: 1;
		grid-column: auto;
		justify-self: center;
	}
`

const SignupButton = styled(Button)`
	justify-self: start;
	align-self: end;
	@media (max-width: ${MobileMaxWidth}) {
		order: 2;
		justify-self: center;
		margin-top: 25px;
		margin-bottom: 15px;
	}
`
