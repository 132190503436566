import { getPrice } from 'api/public'
import { getFeatures, getTheme, getTranslations } from 'api/tenants'
import { DefaultFeatures, Features } from 'constants/features'
import { DefaultTheme, Theme } from 'constants/styles'
import { DefaultTenant, getSubdomain } from 'helpers/tenant'
import { Language } from 'languages/Language'
import { Translations } from 'languages/Translations'
import { DefaultTranslations } from 'languages/translations/default'
import { Broker } from 'models/Broker'
import { Eligibility } from 'models/Eligibility'
import { SubscriptionInfo } from 'models/SubscriptionInfo'
import { TenantInfo } from 'models/TenantInfo'
import { UserInfo } from 'models/UserInfo'
import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react'
import { ThemeProvider } from 'styled-components/macro'
import { NotYetImplemented } from 'utils/func'

interface AppState {
	userInfo?: UserInfo
	subscriptionInfo?: SubscriptionInfo
	eligibility?: Eligibility
	authenticated: boolean
	language: Language
	translations: Translations
	tenant: string
	broker?: Broker
	availableBrokers: Broker[]
	basePrice: number
	theme: Theme
	features: Features
}

interface AppContext extends Readonly<AppState> {
	signIn: (
		username: string,
		password: string
	) => Promise<{ status: number; error?: string }>
	signOut: () => Promise<void>
	fetchSubscriptionInfo: () => Promise<void>
	fetchTenantInfo: () => Promise<void>
	fetchUserInfo: () => Promise<void>
	fetchEligibility: () => Promise<void>
	setLanguage: (language: Language) => void
	setBroker: (broker: Broker) => void
}

const LanguageStorageKey = 'language'

const getDefaultLanguage = () => {
	let language = localStorage.getItem(LanguageStorageKey)
	const esBrowser = navigator.language.startsWith('es')
	if (language === 'es' || esBrowser) return 'es'
	else return 'en'
}

const defaultState: AppState = {
	authenticated: false,
	language: getDefaultLanguage(),
	translations: DefaultTranslations,
	tenant: DefaultTenant,
	availableBrokers: [],
	basePrice: 0,
	theme: DefaultTheme,
	features: DefaultFeatures,
}

const defaultContext = {
	...defaultState,
	signIn: NotYetImplemented,
	signOut: NotYetImplemented,
	fetchTenantInfo: NotYetImplemented,
	fetchSubscriptionInfo: NotYetImplemented,
	fetchUserInfo: NotYetImplemented,
	fetchEligibility: NotYetImplemented,
	setLanguage: NotYetImplemented,
	setBroker: NotYetImplemented,
}

const AppContextInstance = createContext<AppContext>(defaultContext)

export const useAppContext = () => useContext(AppContextInstance)

interface Props {
	children: ReactNode
}

export const AppContextProvider = ({ children }: Props) => {
	const [state, setState] = useState<AppState>(defaultState)

	const fetchTenantInfo = useCallback(async () => {
		const response = await window.fetch(`/api/tenant/${getSubdomain()}`)
		if (response.ok) {
			const tenantInfo = TenantInfo.create(await response.json())
			setState(prevState => ({
				...prevState,
				tenant: tenantInfo.tenantKey,
				broker: tenantInfo.defaultBroker,
				availableBrokers: tenantInfo.availableBrokers ?? [],
			}))
		}
	}, [])

	const fetchUserInfo = useCallback(async () => {
		const response = await window.fetch('/api/auth/user')
		if (response.ok) {
			const userInfo = await response.json()
			setState(prevState => ({
				...prevState,
				userInfo,
				authenticated: true,
			}))
		}
	}, [])

	const fetchSubscriptionInfo = useCallback(async () => {
		const response = await window.fetch('/api/subscription')
		if (response.status === 200) {
			const subscriptionInfo = await response.json()
			setState(prevState => ({
				...prevState,
				subscriptionInfo,
			}))
		}
	}, [])

	const fetchEligibility = useCallback(async () => {
		const response = await window.fetch('/api/signup/eligibility')
		if (response.status === 200) {
			const eligibility = await response.json()
			setState(prevState => ({
				...prevState,
				eligibility,
			}))
		}
	}, [])

	const signIn = useCallback(
		async (username: string, password: string) => {
			const response = await window.fetch('/api/auth', {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ username, password }),
			})

			let error: string | undefined
			if (response.ok) await fetchUserInfo()
			else error = await response.text()

			return { status: response.status, error }
		},
		[fetchUserInfo]
	)

	const signOut = useCallback(async () => {
		await fetch('/api/auth', { method: 'delete' })
		setState(defaultState)
	}, [])

	const setLanguage = useCallback((language: Language) => {
		localStorage.setItem(LanguageStorageKey, language)
		setState(prevState => ({
			...prevState,
			language,
		}))
	}, [])

	const setBroker = useCallback((broker: Broker) => {
		setState(prevState => ({
			...prevState,
			broker,
		}))
	}, [])

	useEffect(() => {
		const fetchTheme = async () => {
			const theme = await getTheme(state.tenant)
			setState(prevState => ({
				...prevState,
				theme,
			}))
		}
		fetchTheme()
	}, [state.tenant])

	useEffect(() => {
		const fetchFeatures = async () => {
			const features = await getFeatures(state.tenant)
			setState(prevState => ({
				...prevState,
				features,
			}))
		}
		fetchFeatures()
	}, [state.tenant])

	useEffect(() => {
		const fetchBasePrice = async () => {
			if (!state.tenant) return
			const basePrice = await getPrice(state.tenant)
			setState(prevState => ({
				...prevState,
				basePrice,
			}))
		}
		fetchBasePrice()
	}, [state.tenant])

	useEffect(() => {
		const fetchTranslations = async () => {
			const translations = await getTranslations(state.tenant, state.language)
			setState(prevState => ({
				...prevState,
				translations,
			}))
		}
		fetchTranslations()
	}, [state.tenant, state.language])

	useEffect(() => {
		fetchUserInfo()
	}, [fetchUserInfo])

	const context = {
		...state,
		signIn,
		signOut,
		fetchTenantInfo,
		fetchSubscriptionInfo,
		fetchEligibility,
		fetchUserInfo,
		setLanguage,
		setBroker,
	}

	return (
		<AppContextInstance.Provider value={context}>
			<ThemeProvider theme={state.theme}>{children}</ThemeProvider>
		</AppContextInstance.Provider>
	)
}
