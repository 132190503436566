import { Beneficiary } from 'models/Beneficiary'
import { parseBoolean, parseNumber, parseString } from 'models/parsing'

export class SignupDetails {
	tenantKey: string = ''
	brokerAlias?: string
	firstName: string = ''
	middleName: string = ''
	lastName: string = ''
	address: string = ''
	address2: string = ''
	city: string = ''
	state: string = ''
	zip: string = ''
	email: string = ''
	phone: string = ''
	birthdate: string = ''
	promoCode: string = ''
	donation: number = 0
	termsAndConditionsAgreed: boolean = false
	password: string = ''
	repeatedPassword: string = ''
	beneficiary: Beneficiary = Beneficiary.Me
	purchaserFirstName: string = ''
	purchaserMiddleName: string = ''
	purchaserLastName: string = ''
	purchaserAddress: string = ''
	purchaserAddress2: string = ''
	purchaserCity: string = ''
	purchaserState: string = ''
	purchaserZip: string = ''
	purchaserPhone: string = ''
	purchaserBirthdate: string = ''
	ssn: string = ''
	membershipContractAgreed: boolean = false
	initials: string = ''
	signature: string = ''
	paymentMethod: string = ''
	cardHolder: string = ''

	static create(obj: any = {}): SignupDetails {
		return {
			tenantKey: parseString(obj.tenantKey),
			brokerAlias: obj.brokerAlias ? parseString(obj.brokerAlias) : undefined,
			firstName: parseString(obj.firstName),
			middleName: parseString(obj.middleName),
			lastName: parseString(obj.lastName),
			address: parseString(obj.address),
			address2: parseString(obj.address2),
			city: parseString(obj.city),
			state: parseString(obj.state),
			zip: parseString(obj.zip),
			email: parseString(obj.email),
			phone: parseString(obj.phone),
			birthdate: parseString(obj.birthdate),
			promoCode: parseString(obj.promoCode),
			donation: parseNumber(obj.donation),
			termsAndConditionsAgreed: parseBoolean(obj.termsAndConditionAgreed),
			password: parseString(obj.password),
			repeatedPassword: parseString(obj.repeatedPassword),
			beneficiary: parseNumber(obj.beneficiary),
			purchaserFirstName: parseString(obj.firstName),
			purchaserMiddleName: parseString(obj.middleName),
			purchaserLastName: parseString(obj.lastName),
			purchaserAddress: parseString(obj.address),
			purchaserAddress2: parseString(obj.address2),
			purchaserCity: parseString(obj.city),
			purchaserState: parseString(obj.state),
			purchaserZip: parseString(obj.zip),
			purchaserPhone: parseString(obj.phone),
			purchaserBirthdate: parseString(obj.birthdate),
			ssn: parseString(obj.ssn),
			membershipContractAgreed: parseBoolean(obj.membershipContractAgreed),
			initials: parseString(obj.initials),
			signature: parseString(obj.signature),
			paymentMethod: parseString(obj.paymentMethod),
			cardHolder: parseString(obj.cardHolder),
		}
	}
}
