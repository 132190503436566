import { GlobalAdminDashboardBatchImports } from 'components/pages/GlobalAdminDashboard/GlobalAdminDashboardBatchImports'
import { GlobalAdminDashboardBatchImportUpload } from 'components/pages/GlobalAdminDashboard/GlobalAdminDashboardBatchImportUpload'
import { GlobalAdminDashboardOverview } from 'components/pages/GlobalAdminDashboard/GlobalAdminDashboardOverview'
import { ManagerFrame } from 'components/widgets/ManagerFrame'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

export const GlobalAdminDashboardPage = () => (
	<ManagerFrame nav={navigation}>
		<Switch>
			<Route exact path="/dashboard" component={GlobalAdminDashboardOverview} />
			<Route
				exact
				path="/dashboard/batch-imports"
				component={GlobalAdminDashboardBatchImports}
			/>
			<Route
				exact
				path="/dashboard/batch-imports/:id"
				component={GlobalAdminDashboardBatchImportUpload}
			/>
			<Route component={() => <div>Page Not Found</div>} />
		</Switch>
	</ManagerFrame>
)

const navigation = [
	{ label: 'Overview', to: '/dashboard' },
	{ label: 'Batch Imports', to: '/dashboard/batch-imports' },
]
