import { getGtagId } from 'api/config'
import { useScript } from 'helpers/useScript'
import { useEffect } from 'react'

declare global {
	interface Window {
		dataLayer: any
	}
}

export const useAnalytics = () => {
	useScript('https://www.googletagmanager.com/gtag/js')

	useEffect(() => {
		;(async () => {
			window.dataLayer = window.dataLayer || []
			function gtag(...args: any) {
				window.dataLayer.push(arguments)
			}

			const gtagId = await getGtagId()
			if (gtagId) {
				gtag('js', new Date())
				gtag('config', gtagId)
			}
		})()
	}, [])
}
