import {
	getLastWillRecipient,
	getTestaments,
	putLastWillRecipient,
	uploadTestaments,
} from 'api/lastWill'
import { Button } from 'components/controls/Button'
import { FileControl } from 'components/controls/FileControl'
import { FileInput } from 'components/controls/FileInput'
import { Section } from 'components/controls/Section'
import { TextInput } from 'components/controls/TextInput'
import { UserDashboardFrame } from 'components/pages/UserDashboard/UserDashboardFrame'
import { MobileMaxWidth } from 'constants/styles'
import { useForm } from 'form/helpers'
import { FileType } from 'models/FileType'
import { LastWillUpdate } from 'models/LasttWillUpdate'
import React, { useEffect, useRef, useState } from 'react'
import { FiSave } from 'react-icons/fi'
import { GiFeather } from 'react-icons/gi'
import styled from 'styled-components/macro'

export const UserDashboardLastWill = () => {
	const formRef = useRef<HTMLFormElement>(null)
	const [fileSelected, setFileSelected] = useState(false)
	const [loading, setLoading] = useState(false)
	const [testaments, setTestaments] = useState<string[]>([])
	const [lastWillUpdate, setLastWillUpdate] = useState<LastWillUpdate>(
		LastWillUpdate.create()
	)
	const propsFor = useForm(lastWillUpdate, setLastWillUpdate, {})

	useEffect(() => {
		getTestaments().then(setTestaments)
	}, [])

	useEffect(() => {
		getLastWillRecipient().then(setLastWillUpdate)
	}, [])

	const uploadFile = async () => {
		if (!formRef.current) return
		setLoading(true)
		const formData = new FormData(formRef.current)
		await uploadTestaments(formData)
		setTestaments(await getTestaments())
		setLoading(false)
	}

	const onDeleted = async () => {
		setLoading(true)
		setTestaments(await getTestaments())
		setLoading(false)
	}

	const updateRecipient = async () => {
		setLoading(true)
		await putLastWillRecipient(lastWillUpdate)
		setLoading(false)
	}

	return (
		<UserDashboardFrame
			icon={GiFeather}
			introduction="Upload and store your own last will and testament. Have this
		important backup ready in time of need."
		>
			<Section>
				<Grid>
					<TestamentGrid>
						<TextInput
							{...propsFor({
								id: 'recipient',
								label: 'Testament Recipient',
								placeholder: 'Please provide email',
							})}
						/>
						<SaveButton
							text="Save Recipient"
							icon={FiSave}
							onClick={updateRecipient}
							loading={loading}
						/>
					</TestamentGrid>
				</Grid>
			</Section>
			<Section white>
				<Header>
					After your passing this file will be sent to the recipient above.
				</Header>
				<Grid>
					{testaments.length === 0 ? (
						<>
							<form ref={formRef}>
								<FileInput onChange={f => setFileSelected(!!f?.length)} />
							</form>
							<Button
								text="Upload File"
								onClick={uploadFile}
								disabled={!fileSelected}
								loading={loading}
							/>
						</>
					) : (
						<DeleteHint>
							This is your testament. If you want to upload a new document,
							delete the existing one below.
						</DeleteHint>
					)}
					{testaments.map(filename => (
						<FileControl
							key={filename}
							filename={filename}
							type={FileType.Testament}
							onDeleted={onDeleted}
						/>
					))}
				</Grid>
			</Section>
		</UserDashboardFrame>
	)
}

const Grid = styled.div`
	display: grid;
	flex-direction: column;
	row-gap: 10px;
`

const TestamentGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: end;
	row-gap: 0;
	grid-column-gap: 10px;
	@media (max-width: ${MobileMaxWidth}) {
		grid-template-columns: 1fr;
		row-gap: 10px;
		grid-column-gap: 0;
	}
`

const SaveButton = styled(Button)`
	height: 45px;
`

const Header = styled.div`
	font-size: 24px;
	text-align: center;
	margin-bottom: 40px;
`

const DeleteHint = styled.div`
	font-size: 20px;
	text-align: center;
	margin-bottom: 20px;
`
