import { H1 } from 'components/controls/H1'
import { Section } from 'components/controls/Section'
import { Heading } from 'components/widgets/Heading'
import { MobileMaxWidth } from 'constants/styles'
import { Eligibility } from 'models/Eligibility'
import React from 'react'
import { useAppContext } from 'store/AppContext'
import styled from 'styled-components/macro'

export const EligibilityPage = () => {
	const { eligibility } = useAppContext()

	let message = ''
	switch (eligibility) {
		case Eligibility.UnknownBirthdate:
			message = 'Uknown Birthdate'
			break
		case Eligibility.TooOld:
			message = 'We do not provide services for people above 79 years of age'
			break
		case Eligibility.UnsupportedState:
			message = 'Currently we only provide services in the state of Texas'
			break
	}

	return (
		<>
			<Heading altImage hideLogin autoScroll />
			<Section>
				<Title>{message}</Title>
			</Section>
			<Section white>
				<Grid>
					<Text>
						We’ll inform you as soon as this changes and services come available
						to you.
					</Text>
				</Grid>
			</Section>
		</>
	)
}

const Title = styled(H1)`
	margin: 0;
	@media (max-width: ${MobileMaxWidth}) {
		margin: 0;
	}
`

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 30px;
	row-gap: 2em;
	@media (max-width: ${MobileMaxWidth}) {
		grid-template-columns: 1fr;
		text-align: center;
	}
`

const Text = styled.div`
	font-size: 24px;
	line-height: 38px;
	color: ${({ theme }) => theme.colors.Stone};
`
