const domainExp = /:\/\/(.*).calmavia.com.*/
export const DefaultTenant = 'calmavia'

export const getSubdomain = () => {
	const domainMatch = window.origin.match(domainExp)
	if (domainMatch?.[1]) {
		if (domainMatch[1].toLowerCase() === 'www') {
			return DefaultTenant
		}
		return domainMatch[1]
	}

	const params = new URLSearchParams(window.location.search)

	return params.get('tenant') ?? DefaultTenant
}
