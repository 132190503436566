export enum UserRole {
	Unknown = '',
	GlobalAdmin = 'global-admin',
	TenantAdmin = 'tenant-admin',
	FuneralDirector = 'funeral-director',
	Bank = 'bank',
	Member = 'member',
}

export const getText = (role?: UserRole): string => {
	switch (role) {
		case UserRole.Bank:
			return 'Bank'
		case UserRole.FuneralDirector:
			return 'Funeral Director'
		case UserRole.GlobalAdmin:
			return 'Global Admin'
		case UserRole.Member:
			return 'Member'
		case UserRole.TenantAdmin:
			return 'Tenant Admin'
		default:
			return role ?? ''
	}
}
