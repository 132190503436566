import { H1 } from 'components/controls/H1'
import { Section } from 'components/controls/Section'
import { UserDashboardFamily } from 'components/pages/UserDashboard/UserDashboardFamily'
import { UserDashboardLibrary } from 'components/pages/UserDashboard/UserDashboardLibrary'
import { UserDashboardFrame } from 'components/pages/UserDashboard/UserDashboardFrame'
import { UserDashboardMembership } from 'components/pages/UserDashboard/UserDashboardMembership'
import { UserDashboardLastWill } from 'components/pages/UserDashboard/UserDashboardLastWill'
import { UserDashboardVault } from 'components/pages/UserDashboard/UserDashboardVault'
import { UserDashboardWishlist } from 'components/pages/UserDashboard/UserDashboardWishlist'
import { Footer } from 'components/widgets/Footer'
import { MobileMaxWidth } from 'constants/styles'
import { SubscriptionStatus } from 'models/SubscriptionStatus'
import React, { useEffect } from 'react'
import { Link, NavLink, Route, Switch } from 'react-router-dom'
import { useAppContext } from 'store/AppContext'
import styled, { createGlobalStyle, css } from 'styled-components/macro'
import { useWidth } from 'styling/useWidth'
import { UserDashboardServiceRequest } from 'components/pages/UserDashboard/UserDashboardServiceRequest'
import { Button } from 'components/controls/Button'
import { FaHandsHelping } from 'react-icons/fa'

export const UserDashboardPage = () => {
	const { isMobile } = useWidth()
	const { tenant, subscriptionInfo, userInfo } = useAppContext()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const active = subscriptionInfo?.status === SubscriptionStatus.Active

	if (!userInfo) return <div>Loading...</div>

	return (
		<>
			<Container>
				<HeadingLogo src={`/tenants/${tenant}/logo.png`} />
				<Link to="/dashboard/service-request">
					<ServiceButton text="Request Service" icon={FaHandsHelping} />
				</Link>
				<Heading isMobile={isMobile}>
					<HeadText>
						{active ? 'You’re all set' : 'There’s an issue with your payment'}
					</HeadText>
				</Heading>
				<ActiveNavStyle />
				<Menubar>
					<MenuItem
						to="/dashboard"
						exact
						activeClassName="UserDashboard__MenuItem--active"
					>
						Membership
					</MenuItem>
					<MenuItem
						to="/dashboard/family"
						exact
						activeClassName="UserDashboard__MenuItem--active"
					>
						Family
					</MenuItem>
					<MenuItem
						to="/dashboard/last-will"
						exact
						activeClassName="UserDashboard__MenuItem--active"
					>
						Last Will &amp; Testament
					</MenuItem>
					<MenuItem
						to="/dashboard/library"
						exact
						activeClassName="UserDashboard__MenuItem--active"
					>
						Library
					</MenuItem>
					<MenuItem
						to="/dashboard/wishlist"
						exact
						activeClassName="UserDashboard__MenuItem--active"
					>
						Wish List
					</MenuItem>
					<MenuItem
						to="/dashboard/vault"
						exact
						activeClassName="UserDashboard__MenuItem--active"
					>
						Vault
					</MenuItem>
				</Menubar>
				<Switch>
					<Route
						exact
						path="/dashboard/service-request"
						component={UserDashboardServiceRequest}
					/>
					<Route exact path="/dashboard" component={UserDashboardMembership} />
					<Route
						exact
						path="/dashboard/family"
						component={UserDashboardFamily}
					/>
					<Route
						exact
						path="/dashboard/library"
						component={UserDashboardLibrary}
					/>
					<Route
						exact
						path="/dashboard/wishlist"
						component={UserDashboardWishlist}
					/>
					<Route
						exact
						path="/dashboard/last-will"
						component={UserDashboardLastWill}
					/>
					<Route exact path="/dashboard/vault" component={UserDashboardVault} />
					<Route component={UserDashboardFrame} />
				</Switch>
			</Container>
			<Footer />
		</>
	)
}

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
`

interface HeadingProps {
	isMobile: boolean
}

const Heading = styled(Section)<HeadingProps>`
	height: 300px;
	background-position: center;
	background-size: cover;
	${p => {
		const suffix = p.isMobile ? '_mobile' : ''
		return css`
			background-image: url(/images/dashboard_hands${suffix}.jpg);
		`
	}}
`

const HeadingLogo = styled.img`
	position: absolute;
	top: 20px;
	left: 20px;
	max-width: 70px;
	cursor: pointer;
	z-index: 1;
	@media (max-width: ${MobileMaxWidth}) {
		top: 10px;
		left: 10px;
	}
`

const ServiceButton = styled(Button)`
	position: absolute;
	top: 20px;
	right: 20px;
	text-decoration: none;
	font-size: 16px;
	padding: 5px 15px;
	color: ${({ theme }) => theme.colors.BlueStone};
	background: rgba(255, 255, 255, 0.6);
`

const HeadText = styled(H1)`
	margin: 0;
	text-align: center;
	font-size: 4em;
	color: #fff;
	@media (max-width: ${MobileMaxWidth}) {
		margin-top: 150px;
		font-size: 3em;
	}
`

const Menubar = styled.div`
	display: grid;
	grid-template-columns: repeat(6, auto);
	align-items: stretch;
	width: 100%;
	background: ${({ theme }) => theme.colors.Sand};
	@media (max-width: ${MobileMaxWidth}) {
		grid-template-columns: repeat(3, 1fr);
	}
`

const MenuItem = styled(NavLink)`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 20px 5px 14px 5px;
	border-bottom: 6px solid transparent;
	font-size: 20px;
	text-decoration: none;
	color: ${({ theme }) => theme.colors.BlueStone};
	transition: background-color 0.1s ease-out;
	:hover {
		background: ${({ theme }) => theme.colors.Gold};
	}
	@media (max-width: ${MobileMaxWidth}) {
		font-size: 16px;
		padding: 20px 5px;
		border: none;
	}
`

const ActiveNavStyle = createGlobalStyle`
	.UserDashboard__MenuItem--active {
		border-color: ${({ theme }) => theme.colors.Gold};
		@media (max-width: ${MobileMaxWidth}) {
			border: none;
			background: ${({ theme }) => theme.colors.Gold};
		}
	}
`
