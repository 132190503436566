import { Checkbox } from 'components/controls/Checkbox'
import { Responsive } from 'components/controls/Responsive'
import { TextInput } from 'components/controls/TextInput'
import { ValidationError } from 'components/controls/ValidationError'
import { LandingGrid } from 'components/widgets/LandingGrid'
import { MobileMaxWidth } from 'constants/styles'
import { useForm } from 'form/helpers'
import { useLanguage } from 'languages/hooks'
import { SignupDetails } from 'models/SignupDetails'
import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components/macro'
import { ErrorsOf } from 'validation/ErrorsOf'

interface Props {
	form: SignupDetails
	setForm: Dispatch<SetStateAction<SignupDetails>>
	errors: ErrorsOf<SignupDetails>
}

export const ContractSetup = ({ form, setForm, errors }: Props) => {
	const { translations } = useLanguage()
	const propsFor = useForm(form, setForm, errors)

	const firstNameSign = form.purchaserFirstName || form.firstName || 'X'
	const lastNameSign = form.purchaserLastName || form.lastName || 'X'

	return (
		<LandingGrid>
			<Title>{translations['contractSetup.title']}</Title>
			<TextInput
				{...propsFor({
					id: 'initials',
					label: translations['contractSetup.initials'],
					placeholder: `${firstNameSign[0].toUpperCase()}${lastNameSign[0].toUpperCase()}`,
				})}
				required
				upperCase
			/>
			<TextInput
				{...propsFor({
					id: 'signature',
					label: translations['contractSetup.signature'],
					placeholder: `${firstNameSign.toUpperCase()} ${lastNameSign.toUpperCase()}`,
				})}
				required
				upperCase
			/>
			<div />
			{/* <TextInput
				{...propsFor({
					id: 'ssn',
					label: 'SSN / TAX-ID',
					placeholder: 'XXX-XX-XXXX',
				})}
				required
			/> */}
			<SsnHint>{translations['contractSetup.ssnRequirment']}</SsnHint>
			<Responsive
				desktop={
					<MembershipAgreement>
						{translations['contractSetup.membershipIntro']}
						<Link href="/files/MembershipAgreement.pdf" target="_blank">
							{translations['contractSetup.readMore']}
						</Link>
					</MembershipAgreement>
				}
			/>
			<CheckboxContainer>
				<Checkbox
					bordered
					text={translations['contractSetup.acceptMembership']}
					checked={form.membershipContractAgreed}
					onChange={checked =>
						setForm(state => ({ ...state, membershipContractAgreed: checked }))
					}
				/>
				{errors.membershipContractAgreed && (
					<ValidationError>{errors.membershipContractAgreed}</ValidationError>
				)}
			</CheckboxContainer>
			<Responsive
				mobile={
					<Link href="/files/MembershipAgreement.pdf" target="_blank">
						Read CalmaVia MEMBERSHIP AGREEMENT
					</Link>
				}
			/>
			<Disclaimer>{translations['contractSetup.disclaimer']}</Disclaimer>
		</LandingGrid>
	)
}

const Title = styled.div`
	font-size: 30px;
	line-height: 50px;
	@media (max-width: ${MobileMaxWidth}) {
		order: -2;
		font-size: 24px;
		line-height: 31px;
		text-align: center;
	}
`

const SsnHint = styled.div`
	grid-column: span 2;
	font-size: 24px;
	line-height: 38px;
	color: ${({ theme }) => theme.colors.Stone};
	@media (max-width: ${MobileMaxWidth}) {
		grid-column: auto;
		order: -1;
		font-size: 15px;
		line-height: 24px;
		text-align: center;
	}
`

const MembershipAgreement = styled.div`
	font-size: 14px;
	line-height: 25px;
	color: ${({ theme }) => theme.colors.Stone};
`

const Link = styled.a`
	text-decoration: underline;
	color: inherit;
	margin-left: 8px;
	:hover {
		color: ${({ theme }) => theme.colors.Sand};
	}
	@media (max-width: ${MobileMaxWidth}) {
		font-size: 11px;
		line-height: 24px;
		text-align: center;
		color: ${({ theme }) => theme.colors.Stone};
	}
`

const CheckboxContainer = styled.div`
	@media (max-width: ${MobileMaxWidth}) {
		margin-top: 30px;
	}
`

const Disclaimer = styled.div`
	grid-column: span 4;
	font-size: 14px;
	line-height: 25px;
	margin-top: 25px;
	color: ${({ theme }) => theme.colors.Stone};
	justify-self: center;
	@media (max-width: ${MobileMaxWidth}) {
		grid-column: auto;
		font-size: 11px;
		line-height: 20px;
	}
`
