import { getBatchImports, postBatch } from 'api/batchImports'
import { Button } from 'components/controls/Button'
import { TextInput } from 'components/controls/TextInput'
import { BatchImport } from 'models/BatchImport'
import React, { Fragment, useState } from 'react'
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

export const GlobalAdminDashboardBatchImports = () => {
	const [batchImports, setBatchImports] = useState<BatchImport[]>([])
	const [loading, setLoading] = useState(false)
	const [tenant, setTenant] = useState('')

	const { push } = useHistory()

	useEffect(() => {
		getBatchImports().then(setBatchImports)
	}, [])

	const createBatch = async () => {
		setLoading(true)
		var batch = await postBatch({ tenant })
		setLoading(false)
		if (batch.id) push(`/dashboard/batch-imports/${batch.id}`)
	}

	return (
		<Container>
			<TextInput
				label="Tenant Key"
				placeholder="fwsgrp, lms, ..."
				value={tenant}
				onChange={setTenant}
			/>
			<Button text="Create" onClick={createBatch} loading={loading} />
			<Table>
				<RowHeader>Tenant</RowHeader>
				<RowHeader>Member Count</RowHeader>
				<RowHeader>Create Date</RowHeader>
				<RowHeader>ID</RowHeader>
				{batchImports.map(b => (
					<Fragment key={b.id}>
						<div>{b.tenant}</div>
						<div>{b.memberCount}</div>
						<div>{b.createDate}</div>
						<BatchLink to={`/dashboard/batch-imports/${b.id}`}>
							{b.id}
						</BatchLink>
					</Fragment>
				))}
			</Table>
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	display: grid;
	row-gap: 20px;
`

const Table = styled.div`
	margin-top: 50px;
	display: grid;
	row-gap: 10px;
	column-gap: 10px;
	grid-template-columns: 1fr 1fr auto auto;
`

const RowHeader = styled.div`
	font-size: 18px;
	opacity: 0.7;
`

const BatchLink = styled(Link)`
	color: ${({ theme }) => theme.colors.DarkGold};
`
