import { MobileMaxWidth } from 'constants/styles'
import { formatPrice } from 'helpers/money'
import { useLanguage } from 'languages/hooks'
import React from 'react'
import { useAppContext } from 'store/AppContext'
import styled, { css } from 'styled-components/macro'

export const Introduction = () => {
	const { basePrice } = useAppContext()
	const { translations } = useLanguage()

	return (
		<>
			<Texts>
				<Block left>{translations['intro.left']}</Block>
				<Block>{translations['intro.right']}</Block>
			</Texts>
			{basePrice > 0 && (
				<Pricing>
					<span>{translations['intro.price.prefix']}</span>
					<Amount>
						${formatPrice(basePrice)}
						{translations['intro.price.andUp']}
					</Amount>
					<span>{translations['intro.price.suffix']}</span>
				</Pricing>
			)}
		</>
	)
}

const Texts = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 24px;
	line-height: 38px;
	@media (max-width: ${MobileMaxWidth}) {
		flex-direction: column;
		text-align: center;
		font-size: 15px;
		line-height: 24px;
	}
`

const Block = styled.div`
	flex: 1;
	color: ${({ theme }) => theme.colors.Stone};
	${({ left }: { left?: boolean }) =>
		left
			? css`
					padding-right: 20px;
					@media (max-width: ${MobileMaxWidth}) {
						padding-right: 0;
						padding-bottom: 2em;
					}
			  `
			: css`
					padding-left: 20px;
					@media (max-width: ${MobileMaxWidth}) {
						padding-left: 0;
					}
			  `}
`

const Pricing = styled.div`
	text-align: center;
	margin-top: 45px;
	font-size: 36px;
	line-height: 50px;
	@media (max-width: ${MobileMaxWidth}) {
		display: flex;
		flex-direction: column;
		margin-top: 1em;
		font-size: 24px;
		line-height: 32px;
		text-align: center;
	}
`

const Amount = styled.span`
	&::before {
		content: ' ';
	}
	&::after {
		content: ' ';
	}
	color: ${({ theme }) => theme.colors.Sand};
	@media (max-width: ${MobileMaxWidth}) {
		&::before {
			content: none;
		}
		&::after {
			content: none;
		}
	}
`
