import React from 'react'
import styled, { css } from 'styled-components/macro'

interface Props {
	label: string
	value?: string
}

export const KpiWidget = ({ label, value }: Props) => (
	<Card>
		<Label>{label ?? 'unknown'}</Label>
		<Value>{value}</Value>
	</Card>
)

const Card = styled.div`
	${({ theme }) => css`
		padding: 15px 30px 30px 30px;
		background: ${theme.colors.Air};
		border-radius: 3px;
		border: 1px solid ${theme.colors.Stone};
		box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.2);
	`}
`

const Value = styled.div`
	font-size: 20px;
	word-break: break-all;
`

const Label = styled.div`
	padding-bottom: 10px;
	opacity: 0.6;
`
