import { Responsive } from 'components/controls/Responsive'
import { MobileLeftRightPadding } from 'components/controls/Section'
import { MobileMaxWidth } from 'constants/styles'
import { useLanguage } from 'languages/hooks'
import React from 'react'
import { FiCheck } from 'react-icons/fi'
import styled, { css } from 'styled-components/macro'
import { useWidth } from 'styling/useWidth'

export const Features = () => {
	const { translations } = useLanguage()
	const { isMobile } = useWidth()

	return (
		<Container>
			<Description>
				<Title>
					{translations['features.title1']}
					<br />
					{translations['features.title2']}
				</Title>
				<CallUs>{translations['features.subTitle']}</CallUs>
				<List>
					{translations['features.24/7']}
					<ListItem>
						<CheckIcon /> {translations['features.bodyPickUp']}
					</ListItem>
					<ListItem>
						<CheckIcon /> {translations['features.refrigerate']}
					</ListItem>
					<ListItem>
						<CheckIcon /> {translations['features.cremation']}
					</ListItem>
					<ListItem>
						<CheckIcon /> {translations['features.delivery']}
					</ListItem>
				</List>
				<Notes>
					{translations['features.disclaimer1']}
					<Responsive desktop={<br />} mobile=" " />
					{translations['features.disclaimer2']}
				</Notes>
			</Description>
			<Image isMobile={isMobile} />
		</Container>
	)
}

const Container = styled.span`
	display: flex;
	width: 100%;
	@media (max-width: ${MobileMaxWidth}) {
		flex-direction: column;
		text-align: center;
	}
`

const Description = styled.div`
	width: 50%;
	padding-right: 90px;
	@media (max-width: ${MobileMaxWidth}) {
		width: 100%;
		padding-right: 0;
	}
`

const Title = styled.div`
	font-size: 48px;
	@media (max-width: ${MobileMaxWidth}) {
		font-size: 30px;
	}
`

const CallUs = styled.div`
	font-size: 24px;
	line-height: 38px;
	margin-top: 92px;
	color: ${({ theme }) => theme.colors.Stone};
	@media (max-width: ${MobileMaxWidth}) {
		margin-top: 40px;
		font-size: 17px;
		line-height: 28px;
	}
`

const List = styled.div`
	font-size: 18px;
	line-height: 31px;
	margin-top: 33px;
	@media (max-width: ${MobileMaxWidth}) {
		font-size: 15px;
		line-height: 24px;
	}
`

const ListItem = styled.div`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colors.Stone};
	@media (max-width: ${MobileMaxWidth}) {
		justify-content: center;
	}
`

const CheckIcon = styled(FiCheck)`
	margin-right: 5px;
`

const Notes = styled.div`
	font-size: 14px;
	line-height: 25px;
	margin-top: 30px;
	color: ${({ theme }) => theme.colors.Stone};
`

interface ImageProps {
	isMobile?: boolean
}

const Image = styled.div`
	flex: 1;
	position: relative;
	margin: -100px 0;
	background-position: center;
	background-size: cover;
	${(p: ImageProps) => {
		const suffix = p.isMobile ? '_mobile' : ''
		return css`
			background-image: url(/images/features_father_and_son${suffix}.jpg);
		`
	}}
	@media (max-width: ${MobileMaxWidth}) {
		flex: auto;
		margin: 0;
		margin-top: 3em;
		height: 360px;
		margin-left: -${MobileLeftRightPadding};
		margin-right: -${MobileLeftRightPadding};
	}
`
