import { UserDashboardFrame } from 'components/pages/UserDashboard/UserDashboardFrame'
import React from 'react'
import { GiRibbonShield } from 'react-icons/gi'

export const UserDashboardVault = () => (
	<UserDashboardFrame
		icon={GiRibbonShield}
		introduction="When the worst happens you have peace of mind that all your
		most important documents (house and car notes, tuitions,
		insurance contracts and others) get into the rights hands,
		according to your wishes."
	/>
)
