import { getMemberStats } from 'api/admin'
import { KpiWidget } from 'components/widgets/KpiWidget'
import { MemberStats } from 'models/MemberStats'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useAppContext } from 'store/AppContext'
import styled from 'styled-components/macro'

export const GlobalAdminDashboardOverview = () => {
	const { userInfo } = useAppContext()
	const [stats, setStats] = useState(MemberStats.create)

	useEffect(() => {
		;(async () => {
			setStats(await getMemberStats())
		})()
	}, [])

	return (
		<Container>
			<KpiWidget label="Email" value={userInfo?.email} />
			<KpiWidget label="Role" value={userInfo?.role} />
			<KpiWidget
				label="Total Contracts"
				value={stats.contractCount.toString()}
			/>
			<KpiWidget label="Total Members" value={stats.memberCount.toString()} />
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
	row-gap: 20px;
	column-gap: 20px;
`
