import { useState, useEffect } from 'react'
import { MobileMaxWidthInPx } from 'constants/styles'

export const useWidth = () => {
	const [width, setWidth] = useState(window.innerWidth)

	useEffect(() => {
		const updateWidth = () => setWidth(window.innerWidth)
		window.addEventListener('resize', updateWidth)
		return () => window.removeEventListener('resize', updateWidth)
	}, [])

	return {
		width,
		isMobile: width <= MobileMaxWidthInPx,
	}
}
