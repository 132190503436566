import { Broker } from 'models/Broker'
import { parseString } from 'models/parsing'

export class TenantInfo {
	tenantKey: string = ''
	defaultBroker?: Broker
	availableBrokers?: Broker[]

	static create(obj: any = {}): TenantInfo {
		return {
			tenantKey: parseString(obj.tenantKey),
			defaultBroker: Broker.create(obj.defaultBroker),
			availableBrokers: obj.availableBrokers?.map((b: any) => Broker.create(b)),
		}
	}
}
