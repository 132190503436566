import { CardElement } from '@stripe/react-stripe-js'
import { Theme } from 'constants/styles'
import React from 'react'
import { useAppContext } from 'store/AppContext'

interface Props {
	white?: boolean
	className?: string
	onBlur?: () => Promise<void>
}

export const CardSection = ({ className, onBlur, ...rest }: Props) => {
	const { theme } = useAppContext()

	return (
		<div className={className}>
			<CardElement options={buildOptions({ ...rest, theme })} onBlur={onBlur} />
		</div>
	)
}

interface BuildOptions extends Props {
	theme: Theme
}

const buildOptions = ({ white, theme }: BuildOptions) => ({
	style: {
		base: {
			'fontSize': '18px',
			'lineHeight': '45px',
			'color': '#23323c',
			'backgroundColor': white ? '#fff' : theme.colors.Air,
			'fontFamily': '"Lexend Deca", sans-serif',
			'::placeholder': { color: '#c5c5df' },
		},
	},
})
