import { UserDashboardFrame } from 'components/pages/UserDashboard/UserDashboardFrame'
import React from 'react'
import { GiFairyWand } from 'react-icons/gi'

export const UserDashboardWishlist = () => (
	<UserDashboardFrame
		icon={GiFairyWand}
		introduction="You can use our online tool to plan your funeral and inform
		your family about your wishes."
	/>
)
