import { parseNumber, parseString } from 'models/parsing'

export class BatchImport {
	id?: string = ''
	tenant?: string = ''
	stripeSubscriptionId?: string = ''
	memberCount?: number = 0
	createDate?: string = ''

	static create(obj: any = {}): BatchImport {
		return {
			id: parseString(obj.id),
			tenant: parseString(obj.tenant),
			stripeSubscriptionId: parseString(obj.stripeSubscriptionId),
			memberCount: parseNumber(obj.memberCound),
			createDate: parseString(obj.createDate),
		}
	}
}
