export enum SubscriptionStatus {
	Unknown = 'unknown',
	Incomplete = 'incomplete',
	Incomplete_expired = 'incomplete_expired',
	Trialing = 'trialing',
	Active = 'active',
	Past_due = 'past_due',
	Canceled = 'canceled',
	Unpaid = 'unpaid',
}
