import { getHeaders } from 'api'
import { Section } from 'components/controls/Section'
import { CompanyBanner } from 'components/widgets/CompanyBanner'
import { ContractSetup } from 'components/widgets/ContractSetup'
import { Features } from 'components/widgets/Features'
import { Footer } from 'components/widgets/Footer'
import { Heading } from 'components/widgets/Heading'
import { IntegrationHeading } from 'components/widgets/IntegrationHeading'
import { Introduction } from 'components/widgets/Introduction'
import { PaymentSetup } from 'components/widgets/PaymentSetup'
import { SignupContactDetails } from 'components/widgets/SignupContactDetails'
import { SignupFinalize } from 'components/widgets/SignupFinalize'
import { Testimonials } from 'components/widgets/Testimonials'
import { MobileMaxWidth } from 'constants/styles'
import { DefaultTenant } from 'helpers/tenant'
import { useQuery } from 'helpers/useQuery'
import { SignupDetails } from 'models/SignupDetails'
import { useEffect, useState } from 'react'
import { useAppContext } from 'store/AppContext'
import styled from 'styled-components/macro'
import { ErrorsOf } from 'validation/ErrorsOf'

export const LandingPage = () => {
	const [form, setForm] = useState<SignupDetails>(SignupDetails.create())
	const [errors, setErrors] = useState<ErrorsOf<SignupDetails>>({})
	const [loading, setLoading] = useState<boolean>(false)
	const integration = useQuery('integration')

	const { fetchTenantInfo, fetchUserInfo, authenticated, tenant, broker } =
		useAppContext()

	useEffect(() => {
		fetchTenantInfo()
	}, [fetchTenantInfo])

	useEffect(() => {
		setForm(state => ({
			...state,
			tenantKey: tenant,
			brokerAlias: broker?.alias,
		}))
	}, [broker?.alias, tenant])

	const signup = async () => {
		setLoading(true)
		try {
			const response = await window.fetch('/api/account', {
				method: 'post',
				headers: getHeaders(),
				body: JSON.stringify(form),
			})
			if (response.status === 422) {
				const json = await response.json()
				setErrors(json?.errors)
			}
			if (response.status >= 200 && response.status < 300) {
				await fetchUserInfo()
			}
		} catch (e) {
		} finally {
			setLoading(false)
		}
	}

	const isDefault = tenant === DefaultTenant

	return (
		<Container>
			{!integration && isDefault && <CompanyBanner />}
			{integration ? <IntegrationHeading /> : <Heading />}
			{!integration && isDefault && (
				<Section white padding={false}>
					<Testimonials />
				</Section>
			)}
			{!integration && (
				<>
					<Section>
						<Introduction />
					</Section>

					<FeatureSection white>
						<Features />
					</FeatureSection>
				</>
			)}

			{authenticated ? (
				<Done>
					You are signed up! Check your e-mail and go to{' '}
					<a href={`${window.origin}/dashboard`}>{window.origin}/dashboard</a>
				</Done>
			) : (
				<>
					<Section id="signup-form">
						<SignupContactDetails
							form={form}
							setForm={setForm}
							errors={errors}
						/>
					</Section>

					<Section white>
						<PaymentSetup
							form={form}
							setForm={setForm}
							errors={errors}
							setError={paymentMethod => setErrors({ paymentMethod })}
						/>
					</Section>

					<Section>
						<ContractSetup form={form} setForm={setForm} errors={errors} />
					</Section>

					<Section white>
						<SignupFinalize
							form={form}
							setForm={setForm}
							errors={errors}
							signup={signup}
							loading={loading}
						/>
					</Section>
				</>
			)}
			{!integration && <Footer />}
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
`

const FeatureSection = styled(Section)`
	@media (max-width: ${MobileMaxWidth}) {
		padding-bottom: 0;
	}
`

const Done = styled.div`
	position: fixed;
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: rgba(255, 255, 255, 0.95);
	color: ${({ theme }) => theme.colors.BlueStone};
	font-size: 24px;
	z-index: 1000;
`
