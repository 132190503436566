import { postPasswordRequest } from 'api/public'
import { Button } from 'components/controls/Button'
import { H1 } from 'components/controls/H1'
import { TextInput } from 'components/controls/TextInput'
import { LoginHeading } from 'components/widgets/LoginHeading'
import { useForm } from 'form/helpers'
import { PasswordRequest } from 'models/PasswordRequest'
import React, { FormEvent, useState } from 'react'
import { FiMail } from 'react-icons/fi'
import styled from 'styled-components/macro'

export const PasswordRequestPage = () => {
	const [loading, setLoading] = useState(false)
	const [ok, setOk] = useState(false)
	const [error, setError] = useState('')
	const [form, setForm] = useState<PasswordRequest>(PasswordRequest.create())

	const propsFor = useForm(form, setForm, {})

	const postRequest = async () => {
		setLoading(true)
		setError('')
		const ok = await postPasswordRequest(form)
		setOk(ok)
		setLoading(false)
		if (!ok) {
			setError('Unknown error occured')
		}
	}

	const request = (event: FormEvent) => {
		event.preventDefault()
		postRequest()
	}

	return (
		<form onSubmit={request}>
			<LoginHeading />
			<Container>
				<H1>Request Password Reset</H1>
				<TextInput
					{...propsFor({ id: 'email', label: 'E-Mail' })}
					autoComplete="email"
					required
				/>
				{error && <Error>{error}</Error>}
				<RequestButton
					text="Request Reset Link"
					icon={FiMail}
					onClick={postRequest}
					loading={loading}
					disabled={ok}
					primary
				/>
				{ok && <div>E-Mail sent. Check your mail account!</div>}
			</Container>
		</form>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 30px;
	color: ${({ theme }) => theme.colors.DarkSand};
	margin: 1em 2em;
	& > * {
		max-width: 400px;
		margin-bottom: 1em;
	}
`

const Error = styled.div`
	color: ${({ theme }) => theme.colors.Error};
`

const RequestButton = styled(Button)`
	margin-top: 1em;
`
