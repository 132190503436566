import { postPasswordReset } from 'api/public'
import { Button } from 'components/controls/Button'
import { H1 } from 'components/controls/H1'
import { TextInput } from 'components/controls/TextInput'
import { LoginHeading } from 'components/widgets/LoginHeading'
import { useForm } from 'form/helpers'
import { useQuery } from 'helpers/useQuery'
import { PasswordReset } from 'models/PasswordReset'
import React, { FormEvent, useEffect, useState } from 'react'
import { FiSave } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ErrorsOf } from 'validation/ErrorsOf'

export const PasswordResetPage = () => {
	const [loading, setLoading] = useState(false)
	const [ok, setOk] = useState(false)
	const [unknownError, setUnknownError] = useState(false)
	const [errors, setErrors] = useState<ErrorsOf<PasswordReset>>({})
	const [form, setForm] = useState<PasswordReset>(PasswordReset.create())
	const email = useQuery('email')
	const token = useQuery('token')
	const { push } = useHistory()

	useEffect(() => {
		setForm(state => ({ ...state, email, token }))
	}, [email, token])

	const postRequest = async () => {
		setLoading(true)
		setErrors({})
		const res = await postPasswordReset(form)
		setOk(res.ok)
		setErrors(res.errors)
		setUnknownError(res.unknownError)
		setLoading(false)
	}

	const propsFor = useForm(form, setForm, errors)

	const request = (event: FormEvent) => {
		event.preventDefault()
		postRequest()
	}

	return (
		<form onSubmit={request}>
			<LoginHeading />
			<Container>
				<H1>New Password</H1>
				<TextInput
					{...propsFor({ id: 'email', label: 'E-Mail' })}
					readOnly
					required
				/>
				<TextInput
					{...propsFor({ id: 'newPassword', label: 'New Password' })}
					type="password"
					required
				/>
				<RequestButton
					text="Set Password"
					icon={FiSave}
					loading={loading}
					disabled={ok}
					primary
				/>
				{unknownError && (
					<Error>
						Bad or old link detected. Please request new password reset.
					</Error>
				)}
				{ok && (
					<Info>
						<div>Password updated!</div>
						<Button text="Go to Login" onClick={() => push('/dashboard')} />
					</Info>
				)}
			</Container>
		</form>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 30px;
	color: ${({ theme }) => theme.colors.DarkSand};
	margin: 1em 2em;
	& > * {
		max-width: 400px;
		margin-bottom: 1em;
	}
`

const RequestButton = styled(Button)`
	margin-top: 1em;
`

const Error = styled.div`
	color: ${({ theme }) => theme.colors.Error};
`

const Info = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	& > * {
		margin-bottom: 20px;
	}
`
