import { parseNumber } from 'models/parsing'

export class MemberStats {
	memberCount: number = 0
	contractCount: number = 0

	static create(obj: any = {}): MemberStats {
		return {
			memberCount: parseNumber(obj.memberCount),
			contractCount: parseNumber(obj.contractCount),
		}
	}
}
