import { MobileMaxWidth } from 'constants/styles'
import styled from 'styled-components/macro'

export const LandingGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	column-gap: 30px;
	row-gap: 35px;
	@media (max-width: ${MobileMaxWidth}) {
		grid-template-columns: 1fr;
		row-gap: 20px;
	}
`
