import { BsChatQuote } from 'react-icons/bs'
import styled, { createGlobalStyle } from 'styled-components/macro'
import SwiperCore, {
	EffectCoverflow,
	Navigation,
	Pagination,
	Autoplay,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'

SwiperCore.use([EffectCoverflow, Navigation, Pagination, Autoplay, Navigation])

const SwiperStyle = createGlobalStyle`
	:root {
		--swiper-theme-color: ${p => p.theme.colors.Gold};
	}
`

export const Testimonials = () => {
	return (
		<>
			<SwiperStyle />
			<Container
				loop
				autoplay={{ delay: 15 * 1000 }}
				navigation
				centeredSlides
				grabCursor
				pagination
				spaceBetween={50}
				effect="coverflow"
				coverflowEffect={{
					slideShadows: false,
					rotate: 0,
					stretch: 0,
					depth: 0,
				}}
			>
				{quotes.map(q => (
					<SwiperSlide key={q.name}>
						<QuoteIcon />
						<Text>"{q.text}"</Text>
						<Name>{q.name}</Name>
					</SwiperSlide>
				))}
			</Container>
		</>
	)
}

const Container = styled(Swiper)`
	position: relative;
	background: white;
	padding: 50px 50px 40px 50px;
	width: 100%;
`

const QuoteIcon = styled(BsChatQuote)`
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: -1;
	opacity: 0.06;
`

const Text = styled.div`
	line-height: 1.5em;
`

const Name = styled.div`
	margin-top: 20px;
	font-weight: 200;
	text-align: center;
`

const quotes = [
	{
		text: 'This was our first experience with a death in our family and you walked us through with tender words and warmth.  We all felt cared for and very peaceful. We definitely stay members. Thank you for giving us all that valuable time through our heart break.',
		name: 'B.T., Arlington',
	},
	{
		text: 'Love your service.  We had a wonderful celebration of life gathering with the whole family after we received my son’s ashes. Without you we wouldn’t have been able to afford it.',
		name: 'Deborah C., Sugarland',
	},
	{
		text: 'Thank you and your staff for the professionalism demonstrated during the visitation and funeral service of our son.  We greatly appreciate your assistance throughout our most stressful days.  We were happy to pay extra to change from cremation to burial. My husband would have appreciated it. Your guidance, support and concern will be forever remembered. God Bless You,',
		name: 'Vanessa W., Victoria',
	},
	{
		text: 'We would like to express our sincerest thanks for the kindness, grace and professionalism shown to us during a very difficult time.  It took us quite some time to decide on what kind of service we wanted and you were very patient with us. Cremation and a celebration of life dinner with the extended family was the best solution for us. Our Sincerest thanks,',
		name: 'Debye and kids, Dallas',
	},
	{
		text: 'Our father passed away so unexpected, thank you for taking care of him after the accident. Your service was impeccable and we received his ashes only days after. Very professional with a human touch. Thank you!',
		name: 'Monica G., Robstown',
	},
]
