import { toMegaBytes as formatFileSize } from 'helpers/files'
import React, { ChangeEvent, useRef, useState } from 'react'
import styled from 'styled-components/macro'

interface Props {
	onChange?: (fileList: FileList | null) => void
}

export const FileInput = ({ onChange }: Props) => {
	const fileRef = useRef<HTMLInputElement>(null)
	const [info, setInfo] = useState<File | null>(null)

	const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		setInfo(event.target.files?.item(0) ?? null)
		onChange?.(event.target.files)
	}

	return (
		<Container onClick={() => fileRef.current?.click()}>
			<Label>
				Click here to choose file
				<Input type="file" name="file" ref={fileRef} onChange={onFileChange} />
			</Label>
			{info && (
				<Info>
					{info.name} ({formatFileSize(info.size)})
				</Info>
			)}
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	padding-top: 10px;
	border: 2px solid ${({ theme }) => theme.colors.Sand};
	background: rgba(0, 0, 0, 0.05);
	cursor: pointer;
	:hover {
		background: rgba(0, 0, 0, 0.1);
	}
`

const Label = styled.div`
	font-size: 20px;
	padding-bottom: 10px;
`

const Info = styled.div`
	padding: 10px 0;
	border-top: 2px solid ${({ theme }) => theme.colors.DarkSand};
	font-size: 18px;
`

const Input = styled.input`
	display: none;
`
