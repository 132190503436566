import { MemberStats } from 'models/MemberStats'

export const getMemberStats = async (): Promise<MemberStats> => {
	try {
		const response = await window.fetch('/api/admin/memberStats')

		return await response.json()
	} catch (e) {
		return MemberStats.create()
	}
}
