import { useLanguage } from 'languages/hooks'
import { ErrorsOf } from 'validation/ErrorsOf'

interface Options<F> {
	id: keyof F
	label?: string
	placeholder?: string
}

export const useForm = <F extends object>(
	form: F,
	setForm: React.Dispatch<any>,
	errors?: ErrorsOf<F>,
	translationPrefix?: string
) => {
	const { translations } = useLanguage()

	return ({ id, label, placeholder }: Options<F>) => {
		const value = String(form[id])
		let translatedLabel: string
		if (translationPrefix) {
			translatedLabel =
				(translations as any)[`${translationPrefix}.${id}`] || String(id)
		} else {
			translatedLabel = label || String(id)
		}

		return {
			label: translatedLabel,
			value,
			onChange: (value: string) => {
				setForm({
					...form,
					[id]: value,
				})
			},
			error: errors?.[id],
			placeholder,
		}
	}
}
