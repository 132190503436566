import { DashboardRouter } from 'components/pages/DashboardRouter'
import { EditSubscription } from 'components/pages/EditSubscription'
import { LandingPage } from 'components/pages/LandingPage'
import { PasswordRequestPage } from 'components/pages/PasswordRequest'
import { PasswordResetPage } from 'components/pages/PasswordReset'
import { ProtectedRoute } from 'components/widgets/ProtectedRoute'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'

export const AppRouter = () => (
	<BrowserRouter>
		<Content>
			<Switch>
				<Route exact path="/" component={LandingPage} />
				<Route exact path="/password-request" component={PasswordRequestPage} />
				<Route exact path="/password-reset" component={PasswordResetPage} />
				<ProtectedRoute path="/dashboard" component={DashboardRouter} />
				<ProtectedRoute
					exact
					path="/edit-subscription"
					component={EditSubscription}
				/>
				<Redirect path="/member" to="/dashboard" />
				<Redirect to="/" />
			</Switch>
		</Content>
	</BrowserRouter>
)

const Content = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`
