export interface Features {
	hasPromoCode?: boolean
	hasDonation?: boolean
	hasBrokerChoice?: boolean
}

export const DefaultFeatures: Features = {
	hasPromoCode: true,
	hasDonation: false,
	hasBrokerChoice: false,
}
