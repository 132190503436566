import { H1 } from 'components/controls/H1'
import { EligibilityPage } from 'components/pages/EligibilityPage'
import { UserDashboardPage } from 'components/pages/UserDashboard'
import { Eligibility } from 'models/Eligibility'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppContext } from 'store/AppContext'

export const UserDashboardRouter = () => {
	const [initialized, setInitialized] = useState(false)
	const { fetchSubscriptionInfo, fetchEligibility, eligibility } =
		useAppContext()

	const fetchData = useCallback(async () => {
		await Promise.all([fetchSubscriptionInfo(), fetchEligibility()])
		setInitialized(true)
	}, [fetchSubscriptionInfo, fetchEligibility])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	if (!initialized)
		return <H1>Please wait while we’re fetching your data...</H1>

	if (eligibility !== Eligibility.Eligible) return <EligibilityPage />

	return <UserDashboardPage />
}
