import { getHeaders } from 'api'
import { FamilyMember } from 'models/FamilyMember'
import { ServiceDetails } from 'models/ServiceDetails'

export const getServiceNumber = async (): Promise<string> => {
	try {
		const response = await window.fetch('/api/account/serviceNumber')
		return await response.text()
	} catch (e) {
		return 'Unknown'
	}
}

export const getServiceDetails = async (): Promise<ServiceDetails> => {
	try {
		const response = await window.fetch('/api/account/serviceDetails')
		const details = await response.json()

		return ServiceDetails.create(details)
	} catch (e) {
		return ServiceDetails.create()
	}
}

export const getFamilyMembers = async (): Promise<FamilyMember[]> => {
	try {
		const response = await window.fetch('/api/account/family')
		const members = await response.json()

		return members.map(FamilyMember.create)
	} catch (e) {
		return []
	}
}

export const putFamilyMembers = async (
	members: FamilyMember[]
): Promise<void> => {
	try {
		await window.fetch('/api/account/family', {
			method: 'put',
			body: JSON.stringify(members),
			headers: getHeaders(),
		})
	} catch (e) {}
}
