import React, { ReactNode } from 'react'
import { useWidth } from 'styling/useWidth'

interface Props {
	desktop?: ReactNode
	mobile?: ReactNode
}

export const Responsive = ({ desktop, mobile }: Props) => {
	const { isMobile } = useWidth()

	if (isMobile) return <>{mobile}</>
	return <>{desktop}</>
}
