export const MobileMaxWidthInPx = 1100
export const MobileMaxWidth = `${MobileMaxWidthInPx}px`

export interface Theme {
	colors: {
		Air: string
		BlueStone: string
		Stone: string
		Sand: string
		DarkSand: string
		Gold: string
		DarkGold: string
		Error: string
	}
}

export const DefaultTheme: Theme = {
	colors: {
		Air: '#ededf3',
		BlueStone: '#23323c',
		Stone: '#8291a0',
		Sand: '#c8b9aa',
		DarkSand: '#bdac9a',
		Gold: '#f4c36d',
		DarkGold: '#f1b54c',
		Error: '#be837f',
	},
}
