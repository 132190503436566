import { Translations } from 'languages/Translations'

export const DefaultTranslations: Translations = {
	'aboutUs.link': 'About Us',
	'aboutUs.header':
		'We support families throughout the country preparing for inevitable, we help them to celebrate lives that have been lived, preserving their memories dignified, hassle-free, affordable.',
	'aboutUs.content1':
		'Here at CalmaVia we are dedicated to give every family access to funeral services, regardless of their financial capabilities. We created comprehensive basic online services, amongst others, to protect your and your family’s most important documents. In case of need you’ll find your funeral service ready to take care of your needs, planned and pre-paid. For your whole family.',
	'aboutUs.content2':
		'Our team works restlessly to bring you most dignified and loving service. Because we care.',

	'whatWeDo.link': 'What We Do',
	'whatWeDo.header':
		'Because we care. Our approach to pre-need (the industry of pre-planning and pre-paying funeral services) is different. We take your whole family into consideration, not just individuals.',
	'whatWeDo.content':
		'Together with our strong partners we are able to offer dignified services for an unbeatable low monthly fee. Online services like “Last Will and Testament” and an “Online Vault” for your most important documents and others help you to get organized way before the need arises. When the worst day of your life has come, when you have lost a loved one, you can concentrate on your family and your mourning. The unavoidable details of the funeral service will be taken care of without being a hassle, everything is already determined with no out of pocket cost. If you would want to make last minute changes, you’re more than welcome. We provide access to a professional, worthy cremation that brings the ashes of your loved ones home, where you can celebrate the life lived with family and friends. If you rather have a burial, a local funeral home will take care of your wishes.',

	'faq.link': 'FAQ',

	'careers.link': 'Careers',

	'contactUs.link': 'Contact Us',

	'findUs.link': 'Where to find us',

	'heading.slogan': 'Because we care',
	'heading.title': 'About your family',
	'heading.subTitle': '...we’re here to ease your loss',
	'heading.login': 'Member Login',

	'intro.left':
		'The most unfortunate life situation is to lose a loved one. It will eventually happen, and you want to be prepared to ease the strain on your family. American Benefit Specialist offers the easiest solution available.',
	'intro.right':
		'The only Final-Rest Plan your family will need—the whole family. Subscribe to cover yourself, spouse, and children and prevent all uncertainty during such a stressful moment.',
	'intro.price.prefix': 'For only',
	'intro.price.andUp': '*',
	'intro.price.suffix': 'per month.',
	'intro.price.disclaimer': 'intro.price.disclaimer',

	'features.title1': 'We’re here',
	'features.title2': 'in your time of need',
	'features.subTitle': 'Our service is just one phone call away.',
	'features.24/7': "Call 24/7 and we'll take care of:",
	'features.bodyPickUp': 'Picking up the body',
	'features.refrigerate': 'Refrigeration',
	'features.cremation': 'Performing a cremation',
	'features.delivery': 'Delivering the remains',
	'features.disclaimer1':
		'As a standard service, we help perform a simple cremation and even repatriate remains worldwide.',
	'features.disclaimer2':
		'In case of need, you can also choose from options a funeral home offers for an additional cost.',

	'signupContact.title': 'Calculate your membership and sign up',
	'signupContact.subscription.myself': 'me and my family',
	'signupContact.subscription.parents': 'my parents',
	'signupContact.subscription.children': 'my kids & grandchildren',
	'signupContact.subscription.aboutYourself': 'Please tell us about yourself',
	'signupContact.subscription.aboutBeneficiary':
		'Please tell us about the most senior member of benefitting family',
	'signupContact.subscription.calculatedPrice1': 'Your calculated',
	'signupContact.subscription.calculatedPrice2': 'monthly membership',
	'signupContact.subscription.faqTitle': 'Click here for important information',
	'signupContact.subscription.disclosureAgreement':
		'CalmaVia E-sign Disclosure and Agreement',
	'signupContact.subscription.acceptTerms':
		'I have read and accept the terms and conditions',

	'signupFinalize.title': 'Finalize Signup',
	'signupFinalize.features':
		'Create a password for your online member services, where you’ll also find a variety of features like the ability to add/remove family members and free secure storage for your most important and private documents.',

	'signing.title':
		'Thank you for signing up to American Benefit Specialist’s Final-Rest Plan service.',
	'signing.explanation':
		'To officialize your membership and to recieve our services, we’ll need you to digitally complete and sign some paperwork.',

	'contractSetup.title': 'Setup your Final-Rest Plan membership',
	'contractSetup.ssnRequirment':
		'This membership’s contract is protected by the state. For contract purposes, Final-Rest Plan is addressed as Trust Funded Prepaid Funeral Benefits.',
	'contractSetup.initials': 'Initials',
	'contractSetup.signature': 'Signature',
	'contractSetup.membershipIntro':
		'CalmaVia MEMBERSHIP AGREEMENT This CalmaVia Membership Agreement is made by and between you, individually, and Your Family...',
	'contractSetup.acceptMembership':
		'I have read and accept this membership contract',

	'paymentSetup.title':
		'How would you like to pay for your monthly membership?',
	'paymentSetup.acceptContract':
		'By submitting I acknowledge to have read and accepted the Prepaid Benefits Preneed Contract.',
	'contractSetup.disclaimer':
		'If you are hospitalized at this time or have contracted COVID-19, you’ll have a 4-week waiting period before access to prepaid funeral services.',
	'contractSetup.readMore': 'Read the contract here',

	'user.birthdate': 'Date of birth',
	'user.donation': 'Donation',
	'user.promoCode': 'Promo code',
	'user.firstName': 'First name',
	'user.middleName': 'Middle name',
	'user.lastName': 'Last name',
	'user.address': 'Address line 1',
	'user.address2': 'Address line 2',
	'user.city': 'City',
	'user.state': 'State',
	'user.zip': 'ZIP',
	'user.email': 'Email',
	'user.phone': 'Phone',
	'user.password': 'Choose password',
	'user.repeatedPassword': 'Confirm password',
	'user.purchaserBirthdate': 'Date of birth',
	'user.purchaserFirstName': 'First name',
	'user.purchaserMiddleName': 'Middle name',
	'user.purchaserLastName': 'Last name',
	'user.purchaserAddress': 'Address line 1',
	'user.purchaserAddress2': 'Address line 2',
	'user.purchaserCity': 'City',
	'user.purchaserState': 'State',
	'user.purchaserZip': 'ZIP',
	'user.purchaserEmail': 'Email',
	'user.purchaserPhone': 'Phone',

	'footer.contact': 'Contact',
	'footer.faq': 'FAQ',
	'footer.disclaimer':
		'American Benefit Specialist offers comprehensive services for wellbeing, health and end-of-life planning. The Prepaid Final-Rest Plan is a CalmaVia product providing access to simple cremation services and committed to making the process easier and simpler than a traditional burial.',
	'footer.copyright': 'CalmaVia LLC. All Rights Reserved.',

	'support.email': 'eric@americanbenefitspecialist.com',
	'support.phone': '(832) 248-9070',

	'validation.hasErrors': 'There are some issues with your input above.',
	'general.login': 'Login',
	'general.logOut': 'Log out',
	'general.signUp': 'Sign up',
	'general.submit': 'Submit',
	'general.readMore': 'Read more',
	'general.creditCard': 'Credit card',
	'general.cardHolder': 'Card holder',
}
