import { H1 } from 'components/controls/H1'
import { FuneralDirectorDashboardPage } from 'components/pages/FuneralDirectorDashboardPage'
import { GlobalAdminDashboardPage } from 'components/pages/GlobalAdminDashboard'
import { TenantAdminDashboardPage } from 'components/pages/TenantAdminDashboard'
import { UserDashboardRouter } from 'components/pages/UserDashboard/UserDashboardRouter'
import { UserRole } from 'models/UserRole'
import React from 'react'
import { useAppContext } from 'store/AppContext'

export const DashboardRouter = () => {
	const { userInfo } = useAppContext()

	switch (userInfo?.role) {
		case UserRole.Member:
			return <UserDashboardRouter />

		case UserRole.FuneralDirector:
			return <FuneralDirectorDashboardPage />

		case UserRole.GlobalAdmin:
			return <GlobalAdminDashboardPage />

		case UserRole.TenantAdmin:
			return <TenantAdminDashboardPage />

		case UserRole.Bank:
			return <div>Not yet implemented for this role: {userInfo.role}</div>

		default:
			return <H1>Not Authorized (missing role)</H1>
	}
}
