import { deleteTestament } from 'api/lastWill'
import { Button } from 'components/controls/Button'
import { MobileMaxWidth } from 'constants/styles'
import { FileType } from 'models/FileType'
import React from 'react'
import { useState } from 'react'
import { CgRemove } from 'react-icons/cg'
import { FiDownload } from 'react-icons/fi'
import styled from 'styled-components/macro'

interface Props {
	type: FileType
	filename: string
	onDeleted?: () => void
}

export const FileControl = ({ type, filename, onDeleted }: Props) => {
	const [deleting, setDeleting] = useState(false)

	const deleteFile = async () => {
		setDeleting(true)
		await deleteTestament(filename)
		setDeleting(false)
		onDeleted?.()
	}

	return (
		<Container>
			<Filename>{filename}</Filename>
			<ButtonLink href={`/api/files/${type}/${filename}`} target="_blank">
				<DownloadButton text="Download" icon={FiDownload} />
			</ButtonLink>
			<Button
				text="Delete"
				icon={CgRemove}
				onClick={deleteFile}
				loading={deleting}
			/>
		</Container>
	)
}

const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr auto auto;
	align-items: center;
	background: ${({ theme }) => theme.colors.DarkSand};
	row-gap: 0;
	grid-column-gap: 2px;
	@media (max-width: ${MobileMaxWidth}) {
		grid-template-columns: 1fr;
		row-gap: 2px;
		grid-column-gap: 0;
	}
`

const Filename = styled.div`
	margin-left: 20px;
	@media (max-width: ${MobileMaxWidth}) {
		margin: 20px;
		text-align: center;
	}
`

const ButtonLink = styled.a`
	text-decoration: none;
`

const DownloadButton = styled(Button)`
	width: 100%;
`
