import { getServiceDetails, getServiceNumber } from 'api/member'
import { Section } from 'components/controls/Section'
import { UserDashboardFrame } from 'components/pages/UserDashboard/UserDashboardFrame'
import { useLanguage } from 'languages/hooks'
import { ServiceDetails } from 'models/ServiceDetails'
import React, { useEffect, useRef, useState } from 'react'
import { FaHandsHelping } from 'react-icons/fa'
import { FiDownload, FiPhoneCall } from 'react-icons/fi'
import styled from 'styled-components/macro'

export const UserDashboardServiceRequest = () => {
	const scrollRef = useRef<HTMLDivElement>(null)
	const { language } = useLanguage()
	const [serviceNumber, setServiceNumber] = useState<string>()
	const [serviceDetails, setServiceDetails] = useState<ServiceDetails>()

	useEffect(() => {
		getServiceNumber().then(setServiceNumber)
		getServiceDetails().then(setServiceDetails)
		var current = scrollRef.current
		if (current) {
			setTimeout(() => window.scrollTo(0, current?.offsetTop ?? 0), 1000)
		}
	}, [])

	return (
		<>
			<div ref={scrollRef} />
			<UserDashboardFrame
				introduction="We are very sorry for your loss."
				text="Your membership gives you access to our services as agreed in the contract with our funeral home you can download below."
				icon={FaHandsHelping}
			>
				<BigSection>
					<div>
						Please call this number, our team of funeral directors will take
						care with your service request with all their professionalism and
						personal dedication that you need right now.{' '}
					</div>
					<Thanks>
						Thank you for being a valuable member of our community. We wish you
						all the best.
					</Thanks>
				</BigSection>
				<BigSection white>
					<PhoneLink href={`tel:${serviceNumber}`}>
						<PhoneIcon />
						<div>{serviceNumber}</div>
					</PhoneLink>
				</BigSection>
				{serviceDetails && (
					<>
						<BigSection>
							<Details>
								<DownloadLink
									href={`/files/FAQ.${language}.pdf`}
									target="_blank"
								>
									<DownloadIcon />
									Download FAQ
								</DownloadLink>
								<DownloadLink href={`/files/GPL.pdf`} target="_blank">
									<DownloadIcon />
									Download GPL
								</DownloadLink>
								<DownloadLink href="/api/account/contract" target="_blank">
									<DownloadIcon />
									Download Membership Contract #{serviceDetails.contractId}
								</DownloadLink>
							</Details>
						</BigSection>
						{serviceDetails.familyMembers.length > 0 && (
							<BigSection white>
								<FamilyHeader>Covered Family Members</FamilyHeader>
								<div>
									{serviceDetails?.familyMembers.map(f => (
										<div key={f.id}>
											{`${f.firstName} ${f.middleName} ${f.lastName}`}
										</div>
									))}
								</div>
							</BigSection>
						)}
					</>
				)}
			</UserDashboardFrame>
		</>
	)
}

const BigSection = styled(Section)`
	font-size: 20px;
	text-align: center;
`

const Thanks = styled.div`
	margin-top: 30px;
`
const Details = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const PhoneLink = styled.a`
	display: inline-flex;
	align-items: center;
	font-size: 30px;
	text-decoration: none;
	border-bottom: 3px solid ${({ theme }) => theme.colors.DarkGold};
	color: ${({ theme }) => theme.colors.BlueStone};
	:hover {
		color: ${({ theme }) => theme.colors.DarkGold};
	}
`

const PhoneIcon = styled(FiPhoneCall)`
	margin-right: 10px;
`

const DownloadLink = styled.a`
	display: block;
	color: ${({ theme }) => theme.colors.BlueStone};
	text-decoration: none;
	:not(:last-child) {
		margin-bottom: 20px;
	}
	:hover {
		color: ${({ theme }) => theme.colors.DarkGold};
	}
`

const DownloadIcon = PhoneIcon.withComponent(FiDownload)

const FamilyHeader = styled.div`
	margin-bottom: 20px;
	color: ${({ theme }) => theme.colors.Stone};
`
