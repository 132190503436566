import { DefaultFeatures, Features } from 'constants/features'
import { DefaultTheme, Theme } from 'constants/styles'
import { Language } from 'languages/Language'
import { Translations } from 'languages/Translations'
import { DefaultTranslations } from 'languages/translations/default'

export const getTranslations = async (
	tenant: string,
	language: Language
): Promise<Translations> => {
	try {
		const response = await window.fetch(
			`/tenants/${tenant}/translations.${language}.json`
		)
		return await response.json()
	} catch (e) {
		return DefaultTranslations
	}
}

export const getTheme = async (tenant: string): Promise<Theme> => {
	try {
		const response = await window.fetch(`/tenants/${tenant}/theme.json`)
		return await response.json()
	} catch (e) {
		return DefaultTheme
	}
}

export const getFeatures = async (tenant: string): Promise<Features> => {
	try {
		const response = await window.fetch(`/tenants/${tenant}/features.json`)
		return await response.json()
	} catch (e) {
		return DefaultFeatures
	}
}

export const getTermsAndConditions = async (): Promise<string> => {
	try {
		const response = await window.fetch(`/files/TermsAndConditions.html`)
		return await response.text()
	} catch (e) {
		return ''
	}
}

export const getMembershipAgreement = async (): Promise<string> => {
	try {
		const response = await window.fetch(`/files/MembershipAgreement.html`)
		return await response.text()
	} catch (e) {
		return ''
	}
}
