import { MobileMaxWidth } from 'constants/styles'
import styled from 'styled-components/macro'

export const H1 = styled.div`
	margin-bottom: 115px;
	font-size: 36px;
	line-height: 50px;
	text-align: center;
	@media (max-width: ${MobileMaxWidth}) {
		margin-bottom: 36px;
		font-size: 24px;
		line-height: 31px;
	}
`
