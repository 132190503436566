import { LanguageSelection } from 'components/controls/LanguageSelection'
import { useLanguage } from 'languages/hooks'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

export const IntegrationHeading = () => {
	const { language, setLanguage } = useLanguage()

	return (
		<NavSection>
			<div />
			<LanguageSelection language={language} setLanguage={setLanguage} />
			<LoginLink to="/dashboard">Login</LoginLink>
		</NavSection>
	)
}
const LoginLink = styled(Link)`
	color: ${({ theme }) => theme.colors.Air};
	:hover {
		color: ${({ theme }) => theme.colors.Air};
	}
`

const NavSection = styled.div`
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	place-items: center;
	background: ${({ theme }) => theme.colors.Stone};
	padding: 20px;
	text-align: center;
`
