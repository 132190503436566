import { getText } from 'models/UserRole'
import React, { ReactNode } from 'react'
import { FiLogOut } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import { useAppContext } from 'store/AppContext'
import styled, { css } from 'styled-components/macro'

interface Props {
	children: ReactNode
	nav?: {
		label: string
		to: string
	}[]
}

export const ManagerFrame = ({ children, nav }: Props) => {
	const { userInfo, signOut } = useAppContext()

	return (
		<Container>
			<AppBar>
				<Logo src="/logo192.png" />
				<div>
					{getText(userInfo?.role)}
					<Dashboard>{` `}Dashboard</Dashboard>
				</div>
				<SignOut onClick={signOut}>
					<SignOutText>Sign Out</SignOutText>
					<SignOutIcon />
				</SignOut>
			</AppBar>
			{nav && (
				<Navigation>
					{nav.map(link => (
						<NavItem
							key={link.label}
							to={link.to}
							activeStyle={{ background: 'transparent' }}
							exact
						>
							{link.label}
						</NavItem>
					))}
				</Navigation>
			)}
			<Content>{children}</Content>
		</Container>
	)
}

const Container = styled.div``

const AppBar = styled.div`
	${({ theme }) => css`
		display: grid;
		grid-template-columns: auto auto 1fr;
		align-content: center;
		align-items: center;
		column-gap: 15px;
		height: 78px;
		padding: 0 20px;
		color: ${theme.colors.Air};
		background: ${theme.colors.BlueStone};
		border-bottom: 2px solid ${theme.colors.Gold};
		font-size: 20px;
	`}
`

const Logo = styled.img`
	max-height: 45px;
`

const Navigation = styled.div`
	${({ theme }) => css`
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
		background: ${theme.colors.Air};
		border-bottom: 3px solid ${theme.colors.Air};
	`}
`

const NavItem = styled(NavLink)`
	${({ theme }) => css`
		display: inline;
		padding: 15px 20px;
		color: ${theme.colors.BlueStone};
		text-decoration: none;
		text-align: center;
		white-space: nowrap;
		background: rgba(0, 0, 0, 0.05);
		transition: background-color 0.1s ease-out;
		:hover {
			background: rgba(0, 0, 0, 0.08);
		}
	`}
`

const Dashboard = styled.span`
	color: ${({ theme }) => theme.colors.Stone};
`

const SignOut = styled.div`
	justify-self: end;
	display: flex;
	align-items: center;
	font-size: 16px;
	color: ${({ theme }) => theme.colors.Gold};
	cursor: pointer;
	:hover {
		text-decoration: underline;
	}
`

const SignOutText = styled.span`
	@media (max-width: 600px) {
		display: none;
	}
`

const SignOutIcon = styled(FiLogOut)`
	font-size: 16px;
	margin-left: 10px;
	@media (max-width: 600px) {
		font-size: 22px;
	}
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 20px;
`
