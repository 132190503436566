import { ErrorsOf } from 'validation/ErrorsOf'

export const checkForErrors = <T>(errors: ErrorsOf<T>) => {
	for (const prop in errors) {
		if (!!errors[prop as keyof T]) {
			return true
		}
	}
	return false
}
