import { LandingGrid } from 'components/widgets/LandingGrid'
import { MobileMaxWidth } from 'constants/styles'
import { DefaultTenant } from 'helpers/tenant'
import { useLanguage } from 'languages/hooks'
import { FaEnvelope, FaPhone } from 'react-icons/fa'
import { useAppContext } from 'store/AppContext'
import styled, { css } from 'styled-components/macro'

export const Footer = () => {
	const { tenant, authenticated, signOut } = useAppContext()
	const { translations, language } = useLanguage()

	const isDefaultTenant = tenant === DefaultTenant

	return (
		<Container>
			<Logos>
				<img src={`/tenants/${tenant}/logo.png`} alt="Logo" height={100} />
				{!isDefaultTenant && (
					<img
						src={`/tenants/${DefaultTenant}/logo.png`}
						alt="Logo"
						height={100}
					/>
				)}
			</Logos>
			{authenticated ? (
				<FooterAction onClick={signOut}>
					{translations['general.logOut']}
				</FooterAction>
			) : (
				<div />
			)}
			<Header mobileOrder={2}>
				<TextLink href={`/files/FAQ.${language}.pdf`} target="_blank">
					{translations['footer.faq']}
				</TextLink>
			</Header>
			<Header>{translations['footer.contact']}</Header>
			<Content span={3} mobileOrder={1}>
				{translations['footer.disclaimer']}
				<p>{translations['intro.price.disclaimer']}</p>
			</Content>
			<Content>
				<Mail href={`mailto:${translations['support.email']}`}>
					<MailIcon /> {translations['support.email']}
				</Mail>
				{translations['support.phone'] && (
					<Mail href={`tel:${translations['support.phone']}`}>
						<TelIcon /> {translations['support.phone']}
					</Mail>
				)}
			</Content>
			<Legal>
				<span>
					{`© Copyright ${new Date().getFullYear()} ${
						translations['footer.copyright']
					}`}
				</span>
			</Legal>
		</Container>
	)
}

const Container = styled(LandingGrid)`
	row-gap: 35px;
	padding: 65px 150px 10px 150px;
	background: ${({ theme }) => theme.colors.BlueStone};
	justify-items: start;
	align-items: start;
	@media (max-width: ${MobileMaxWidth}) {
		justify-items: center;
		text-align: center;
		padding: 60px 40px;
	}
`

const Logos = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
	@media (max-width: ${MobileMaxWidth}) {
		flex-direction: column;
	}
`

interface OrderProps {
	mobileOrder?: number
	span?: number
}

const Header = styled.div<OrderProps>`
	align-self: end;
	font-size: 18px;
	line-height: 31px;
	color: #fff;
	@media (max-width: ${MobileMaxWidth}) {
		font-size: 24px;
		line-height: 32px;
		${({ mobileOrder }) =>
			mobileOrder &&
			css`
				order: ${mobileOrder};
			`}
	}
`

const Content = styled.div<OrderProps>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-size: 14px;
	line-height: 25px;
	color: ${({ theme }) => theme.colors.Stone};
	@media (max-width: ${MobileMaxWidth}) {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 15px;
		line-height: 24px;
		${({ mobileOrder }) =>
			mobileOrder &&
			css`
				order: ${mobileOrder};
			`}
	}
	@media (min-width: ${MobileMaxWidth}) {
		${({ span }) =>
			span &&
			css`
				grid-column: span ${span};
			`}
	}
`

const TextLink = styled.a`
	color: inherit;
	:hover {
		color: ${({ theme }) => theme.colors.Gold};
	}
`

const SignupLink = styled.a`
	font-size: 18px;
	line-height: 31px;
	color: #fff;
	cursor: pointer;
	text-decoration: underline;
	:hover {
		color: ${({ theme }) => theme.colors.Gold};
	}
	align-self: end;
	@media (max-width: ${MobileMaxWidth}) {
		font-size: 24px;
		order: 5;
	}
`

const FooterAction = SignupLink.withComponent('span')

const Mail = styled(TextLink)`
	display: flex;
	align-items: center;
	text-decoration: none;
	@media (max-width: ${MobileMaxWidth}) {
		font-size: 17px;
		line-height: 28px;
	}
`

const MailIcon = styled(FaEnvelope)`
	font-size: 1.3em;
	margin-right: 10px;
	@media (max-width: ${MobileMaxWidth}) {
		display: none;
	}
`

const TelIcon = MailIcon.withComponent(FaPhone)

const Legal = styled(Content)`
	grid-column: span 4;
	display: block;
	justify-self: center;
	@media (max-width: ${MobileMaxWidth}) {
		grid-column: auto;
		order: 1000;
		width: 100%;
		display: flex;
		align-items: center;
		margin-top: 2.2em;
		border-top: 2px solid ${({ theme }) => theme.colors.Stone};
		padding-top: 18px;
		font-size: 13px;
		line-height: 24px;
	}
`
