import { parseString } from 'models/parsing'

export class CsvImport {
	content: string = ''

	static create(obj: any = {}): CsvImport {
		return {
			content: parseString(obj.content),
		}
	}
}
