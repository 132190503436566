import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { getPublishableKey } from 'api/public'
import { AppRouter } from 'components/AppRouter'
import { useAnalytics } from 'helpers/useAnalytics'
import { AppContextProvider } from 'store/AppContext'

const stripePromise = async () => loadStripe(await getPublishableKey())

const stripeOptions = {
	fonts: [
		{
			cssSrc:
				'https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap',
		},
	],
}

export const App = () => {
	useAnalytics()

	return (
		<AppContextProvider>
			<Elements stripe={stripePromise()} options={stripeOptions}>
				<AppRouter />
			</Elements>
		</AppContextProvider>
	)
}
