import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Label } from 'components/controls/Label'
import { Responsive } from 'components/controls/Responsive'
import { Required, TextInput } from 'components/controls/TextInput'
import { ValidationError } from 'components/controls/ValidationError'
import { CardSection } from 'components/widgets/CardSection'
import { LandingGrid } from 'components/widgets/LandingGrid'
import { MobileMaxWidth } from 'constants/styles'
import { useForm } from 'form/helpers'
import { useLanguage } from 'languages/hooks'
import { SignupDetails } from 'models/SignupDetails'
import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components/macro'
import { ErrorsOf } from 'validation/ErrorsOf'

interface Props {
	form: SignupDetails
	setForm: Dispatch<SetStateAction<SignupDetails>>
	errors: ErrorsOf<SignupDetails>
	setError: (error?: string) => void
}

export const PaymentSetup = ({ form, setForm, errors, setError }: Props) => {
	const stripe = useStripe()
	const elements = useElements()

	const { translations } = useLanguage()
	const propsFor = useForm(form, setForm, errors)

	const updatePaymentMethod = async () => {
		if (!stripe || !elements) return
		const card = elements.getElement(CardElement)
		if (!card) return

		const { paymentMethod, error } = await stripe.createPaymentMethod({
			type: 'card',
			card,
		})
		setForm(state => ({ ...state, paymentMethod: paymentMethod?.id || '' }))

		if (error) setError(error?.message || 'Card not accepted')
		else setError(undefined)
	}

	return (
		<LandingGrid>
			<Title>{translations['paymentSetup.title']}</Title>
			<Card>
				<Label>
					{translations['general.creditCard']}
					<Required />
				</Label>
				<CardSection onBlur={updatePaymentMethod} />
				<ValidationError>{errors.paymentMethod}</ValidationError>
			</Card>
			<HolderInput
				{...propsFor({
					id: 'cardHolder',
					label: translations['general.cardHolder'],
					placeholder: 'Name on card',
				})}
				autoComplete="cc-name"
				required
				gray
			/>
			<SignContainer>
				<div />
			</SignContainer>
			<div />
			<Responsive
				desktop={
					<>
						<HintLeft>
							<HintTitle>Prepaid Benefits Preneed Contract</HintTitle> For you
							and your family to receive access to funeral goods and services
							the State of Texas protects your rights
						</HintLeft>
						<HintRight>
							by requiring a state issued Trust Funded Prepaid Funeral Contract
							between you and the Funeral Service Provider. This provides
							transparency and helps to prevent misunderstandings, it helps to
							preplan and prepay for your cremation.
							<ContractLink href="/files/EmptyContract.pdf" target="_blank">
								{translations['contractSetup.readMore']}
							</ContractLink>
						</HintRight>
					</>
				}
			/>
			<SignHint>{translations['paymentSetup.acceptContract']}</SignHint>
			<Responsive
				mobile={
					<ContractLink href="/files/EmptyContract.pdf" target="_blank">
						Read Prepaid Benefits Preneed Contract
					</ContractLink>
				}
			/>
		</LandingGrid>
	)
}

const Title = styled.div`
	grid-row: span 2;
	font-size: 34px;
	line-height: 50px;
	@media (max-width: ${MobileMaxWidth}) {
		order: 1;
		grid-row: auto;
		font-size: 24px;
		line-height: 32px;
		text-align: center;
	}
`

const Card = styled.div`
	grid-column: span 3;
	@media (max-width: ${MobileMaxWidth}) {
		order: 2;
		grid-column: auto;
	}
`

const HolderInput = styled(TextInput)`
	grid-column: span 2;
	align-self: start;
	justify-self: start;
	@media (max-width: ${MobileMaxWidth}) {
		order: 3;
		grid-column: auto;
	}
`

const SignContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-self: start;
	justify-self: start;
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 36px;
	@media (max-width: ${MobileMaxWidth}) {
		order: 5;
		justify-self: center;
		margin-top: 30px;
		margin-bottom: 20px;
	}
`

const HintLeft = styled.div`
	align-self: start;
	font-size: 14px;
	line-height: 25px;
	color: ${({ theme }) => theme.colors.Stone};
`

const HintRight = styled(HintLeft)`
	align-self: start;
`

const HintTitle = styled.div`
	color: ${({ theme }) => theme.colors.BlueStone};
`

const SignHint = styled.div`
	align-self: start;
	font-size: 18px;
	line-height: 32px;
	@media (max-width: ${MobileMaxWidth}) {
		order: 6;
		font-size: 15px;
		line-height: 24px;
		text-align: center;
	}
`

const ContractLink = styled.a`
	text-decoration: underline;
	color: inherit;
	margin-left: 8px;
	:hover {
		color: ${({ theme }) => theme.colors.Sand};
	}
	@media (max-width: ${MobileMaxWidth}) {
		order: 7;
		font-size: 14px;
		line-height: 25px;
		text-align: center;
		color: ${({ theme }) => theme.colors.Stone};
	}
`
