import { TextInput } from 'components/controls/TextInput'
import { ManagerFrame } from 'components/widgets/ManagerFrame'
import { SortableTable } from 'components/widgets/SortableTable'
import { TenantMember } from 'models/TenantMember'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import styled from 'styled-components/macro'
import { SortFormat } from 'utils/date'

export const TenantAdminDashboardPage = () => {
	const [loading, setLoading] = useState(false)
	const [query, setQuery] = useState('')
	const [dateFrom, setDateFrom] = useState(moment().add(-1, 'year'))
	const [dateTo, setDateTo] = useState(moment())
	const [members, setMembers] = useState<TenantMember[]>([])
	const [filteredMembers, setfilteredMembers] = useState<TenantMember[]>([])

	useEffect(() => {
		;(async () => {
			setLoading(true)
			const response = await window.fetch(`/api/tenant/members`)
			const clients = await response.json()
			setMembers(clients)
			setLoading(false)
		})()
	}, [])

	useEffect(() => {
		const lowerQuery = query.toLowerCase()
		const filtered = members.filter(m => {
			let found =
				m.email?.toLowerCase().includes(lowerQuery) ||
				m.firstName?.toLowerCase().includes(lowerQuery) ||
				m.lastName?.toLowerCase().includes(lowerQuery)

			if (found && dateFrom) {
				found = moment(m.createDate).isSameOrAfter(dateFrom.startOf('day'))
			}

			if (found && dateTo) {
				found = moment(m.createDate).isSameOrBefore(dateTo.endOf('day'))
			}

			return found
		})

		setfilteredMembers(filtered)
	}, [query, dateFrom, dateTo, members])

	const updateDate = (set: typeof setDateFrom) => (value: Date) => {
		if (value) set(moment(value))
	}

	return (
		<ManagerFrame>
			<Filters>
				<SearchInput
					value={query}
					onChange={setQuery}
					placeholder="Search members..."
					type="search"
					focusOnMount
				/>
				<div>Sign Up From</div>
				<DateFilter
					value={dateFrom?.format('YYYY-MM-DD')}
					onChange={updateDate(setDateFrom)}
					withPortal
				/>
				<div>Sign Up To</div>
				<DateFilter
					value={dateTo?.format('YYYY-MM-DD')}
					onChange={updateDate(setDateTo)}
					withPortal
				/>
			</Filters>
			{loading ? (
				<div>Loading...</div>
			) : filteredMembers.length === 0 ? (
				<div>No Results (maybe change filter)</div>
			) : (
				<Table columns={memberColumns} data={filteredMembers} />
			)}
		</ManagerFrame>
	)
}

const memberColumns = [
	{ Header: 'First Name', accessor: 'firstName' },
	{ Header: 'Last Name', accessor: 'lastName' },
	{ Header: 'Email', accessor: 'email' },
	{
		Header: 'Birthdate',
		accessor: (m: TenantMember) => moment(m.birthday).format(SortFormat),
	},
	{
		Header: 'Sign Up',
		accessor: (m: TenantMember) => moment(m.createDate).format(SortFormat),
	},
]

const Filters = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	row-gap: 10px;
	margin-bottom: 40px;
`

const DateFilter = styled(DatePicker)`
	border: none;
	height: 40px;
	width: 200px;
	padding: 0 10px;
	font-size: 18px;
`

const SearchInput = styled(TextInput)`
	width: 100%;
	grid-column: -1 / 1;
	max-width: 400px;
	margin-bottom: 10px;
`

const Table = styled(SortableTable)`
	margin: 20px 20px 20px 20px;
	td,
	th {
		padding: 10px;
		text-align: left;
	}
	tr:nth-child(even) {
		background: #ccc;
	}
`
