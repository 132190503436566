import { Language } from 'languages/Language'
import { Translations } from 'languages/Translations'
import { useAppContext } from 'store/AppContext'

interface LanguageHookReturnType {
	translations: Translations
	language: Language
	setLanguage: (language: Language) => void
}

export function useLanguage(): LanguageHookReturnType {
	const { translations, language, setLanguage } = useAppContext()

	return {
		translations,
		language,
		setLanguage,
	}
}
