import { postContactRequest } from 'api/public'
import { Button } from 'components/controls/Button'
import { TextInput } from 'components/controls/TextInput'
import { Collapsable } from 'components/widgets/Collapsable'
import { MobileMaxWidth } from 'constants/styles'
import { useForm } from 'form/helpers'
import { useLanguage } from 'languages/hooks'
import { ContactRequest } from 'models/ContactRequest'
import { useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import styled, { css } from 'styled-components/macro'
import { ErrorsOf } from 'validation/ErrorsOf'

type PageTypes =
	| null
	| 'about'
	| 'whatWeDo'
	| 'faq'
	| 'careers'
	| 'contact'
	| 'findUs'

export const CompanyBanner = () => {
	const { language, translations } = useLanguage()
	const [page, setPage] = useState<PageTypes>(null)
	const [form, setForm] = useState<ContactRequest>(ContactRequest.create())
	const [sent, setSent] = useState(false)
	const [loading, setLoading] = useState(false)
	const [errors, setErrors] = useState<ErrorsOf<ContactRequest>>({})
	const propsFor = useForm(form, setForm, errors)

	const createToggleHander = (clickedPage: PageTypes) => () => {
		setPage(page === clickedPage ? null : clickedPage)
	}

	const sendContactRequest = async () => {
		setLoading(true)
		const response = await postContactRequest(form)
		setSent(response.ok)
		setErrors(response.errors ?? {})
		setLoading(false)
	}

	const filledOut = form.fromEmail && form.name && form.request && form.subject

	return (
		<Container>
			<Banner>
				<Link onClick={createToggleHander('about')}>
					{translations['aboutUs.link']}
				</Link>
				<Link onClick={createToggleHander('whatWeDo')}>
					{translations['whatWeDo.link']}
				</Link>
				<Link onClick={createToggleHander('faq')}>
					{translations['faq.link']}
				</Link>
				<Link onClick={createToggleHander('careers')}>
					{translations['careers.link']}
				</Link>
				<Link onClick={createToggleHander('contact')}>
					{translations['contactUs.link']}
				</Link>
				<Link onClick={createToggleHander('findUs')}>
					{translations['findUs.link']}
				</Link>
			</Banner>
			<CollapsableContent open={page === 'about'}>
				<Page>
					<PageHeader>{translations['aboutUs.header']}</PageHeader>
					<PageContent>
						<div>{translations['aboutUs.content1']}</div>
						<div>{translations['aboutUs.content2']}</div>
					</PageContent>
				</Page>
			</CollapsableContent>
			<CollapsableContent open={page === 'whatWeDo'}>
				<Page>
					<PageHeader>{translations['whatWeDo.header']}</PageHeader>
					<PageContent>{translations['whatWeDo.content']}</PageContent>
				</Page>
			</CollapsableContent>
			<CollapsableContent open={page === 'faq'}>
				<Page>
					{language === 'es' ? (
						<>
							<PageHeader>¿Cómo funciona CalmaVia?</PageHeader>
							<PageContent>
								Cuando surge la necesidad, los miembros llaman a un número
								exclusivo para la atención al cliente y los socios de CalmaVia
								se encargan del transporte de su ser querido, la cremación, así
								como la derivación a una d local asociada en el área deseada.
							</PageContent>
							<PageHeader>
								¿Qué pasa si un ser querido muere en otro estado?
							</PageHeader>
							<PageContent>
								No tiene por qué preocuparse por el lugar en el mundo en que
								fallece un miembro de su familia, simplemente llame a nuestro
								número de atención al cliente y CalmaVia se encargará de todo.
							</PageContent>
							<PageHeader>¿A quién cubre la membresía?</PageHeader>
							<PageContent>
								La membresía cubre a los miembros principales, a su cónyuge e
								hijos menores de 23 años.
							</PageContent>
						</>
					) : (
						<>
							<PageHeader>Who is covered under this membership?</PageHeader>
							<PageContent>
								Immediately upon joining, you, your spouse and your dependent
								children (up to the age of 23) receive the full CalmaVia bene
								fits.
							</PageContent>
							<PageHeader>
								How can you offer such competitive pricing?
							</PageHeader>
							<PageContent>
								CalmaVia works with federal and state regulated trustee banks
								and local funeral service providers to offer best services at
								the best prices. CalmaVia also utilizes state of the art
								technology to provide its services and benefits to its members
								in efficient and effective ways.
							</PageContent>
							<PageHeader>What to do, if…?</PageHeader>
							<PageContent>
								Just call the number provided for all our members, a trained and
								experienced funeral director will cordially take care of all
								your needs and questions.
							</PageContent>
							<PageHeader>Do you cover the whole United States?</PageHeader>
							<PageContent>
								Yes, in fact, we cover the whole world. There might be some
								restrictions regarding local regulations which need to be
								handled on a case to case basis.
							</PageContent>
						</>
					)}
				</Page>
			</CollapsableContent>
			<CollapsableContent open={page === 'careers'}>
				<Page>
					<PageHeader>
						Start your career in a growing business. We offer competitive
						benefits for the right candidates.
					</PageHeader>
					<PageContent>
						<P>
							For our office in Houston we are searching for an Office Manager
						</P>
						<P>
							Ensures the highest quality services and products, to satisfy the
							need of any client family, to maximize budgeted profit plans for
							the location, and maintain a positive employee relations
							atmosphere.
						</P>
						<JobHeader>JOB RESPONSIBILITIES</JobHeader>
						<JobSubHeader>Accounting Function Oversight</JobSubHeader>
						<JobItem>• Collections of all accounts receivable</JobItem>
						<JobItem>
							• Verifications and payments of all accounts payable invoices
						</JobItem>
						<JobItem>
							• Controls of receipt and deposit of cash payments received
						</JobItem>
						<JobItem>
							• Maintains petty cash account and disburses the same in
							accordance with company policies and procedures
						</JobItem>
						<JobItem>• Reconciliations of all accounts</JobItem>
						<JobItem>• Cash advance checks</JobItem>
						<JobItem>• Same Day Check requests</JobItem>
						<JobItem>• Bank deposits</JobItem>
						<JobItem>• Verifies/audits cash disbursement reports</JobItem>
						<JobItem>• Tracks Capital Expenditure Authorizations</JobItem>
						<JobSubHeader>Operational Activities</JobSubHeader>
						<JobItem>
							• Orders supplies for the office and completes inventory counts
						</JobItem>
						<JobItem>
							• Coordinates the processing of orders and receipt of all
							merchandise orders
						</JobItem>
						<JobItem>
							• Supports location management to ensure all contracts and work
							orders are completed in a timely manner with proper documentation
						</JobItem>
						<JobItem>
							• Reviews time cards and administers corporate payroll policies
							and procedures
						</JobItem>
						<JobItem>
							• Administers local Human Resources (HR) processes such as
							processing new hire paperwork, maintaining employee files and
							other confidential files (I9’s, etc.).
						</JobItem>
						<JobItem>
							• Ensures new associates receive new hire orientation
						</JobItem>
						<JobItem>• Processes expense reports</JobItem>
						<JobItem>
							• Manages all Alarm Systems (codes, working order, etc.)
						</JobItem>
						<JobItem>• Maintains and updates customer records</JobItem>
						<JobItem>
							• Provides a collaborative, productive workplace environment for
							associate growth and development that instills pride, a sense of
							ownership, and the challenge to associates to exceed expectations
						</JobItem>
						<JobItem>
							• Behaves in a supportive way to enrich the work environment
						</JobItem>
						<JobItem>• Performs other duties as assigned</JobItem>
						<JobHeader>MINIMUM REQUIREMENTS</JobHeader>
						<JobSubHeader>Education</JobSubHeader>
						<JobItem>
							• High school diploma, GED or completion of a diploma-training
							program at a college or technical school
						</JobItem>
						<JobSubHeader>Experience</JobSubHeader>
						<JobItem>
							• Two (2) years bookkeeping, general office, clerical accounting,
							and Accounts Payable experience required
						</JobItem>
						<JobItem>Knowledge, Skills and Abilities</JobItem>
						<JobItem>
							• Solid working knowledge of computers, MS Office, e-mail,
							internet and basic office equipment required
						</JobItem>
						<JobItem>
							• Excellent communication skills both orally and in writing
						</JobItem>
						<JobItem>
							• High level of compassion, integrity and confidentiality
						</JobItem>
						<JobItem>• Problem solving skills</JobItem>
						<JobItem>• Ability to multi task and set priorities</JobItem>
						<JobItem>• Detail oriented</JobItem>
						<JobItem>
							• Must be flexible and able to function in a fast-paced
							environment
						</JobItem>
						<JobHeader>WORK CONDITIONS</JobHeader>
						<JobSubHeader>Work Environment</JobSubHeader>
						<JobItem>• Professional Dress is required.</JobItem>
						<JobItem>• Work Postures</JobItem>
						<JobItem>
							• Sitting continuously for many hours per day, up to 6 hours per
							day
						</JobItem>
						<JobItem>• Climbing stairs to access buildings frequently</JobItem>
						<JobSubHeader>Physical Demands</JobSubHeader>
						<JobItem>
							• Physical effort requiring manual dexterity is required, includes
							paperwork, calculators, computers and phone usage
						</JobItem>
						<JobSubHeader>Work Hours</JobSubHeader>
						<JobItem>
							• Working beyond “standard” hours as the need arises
						</JobItem>
						<P>
							Please contact{' '}
							<AMail href={`mailto:${translations['support.email']}`}>
								{translations['support.email']}
							</AMail>{' '}
							with you CV. We’re looking forward to meeting you in person.
						</P>
					</PageContent>
				</Page>
			</CollapsableContent>
			<CollapsableContent open={page === 'contact'}>
				<Page>
					<ContactRequestForm>
						<TextInput
							required
							readOnly={sent}
							{...propsFor({
								id: 'fromEmail',
								label: 'Email',
							})}
						/>
						<TextInput
							required
							readOnly={sent}
							{...propsFor({
								id: 'name',
								label: 'Name',
							})}
						/>
						<TextInput
							required
							readOnly={sent}
							{...propsFor({
								id: 'subject',
								label: 'Subject',
							})}
						/>
						<TextInput
							required
							rows={4}
							readOnly={sent}
							{...propsFor({
								id: 'request',
								label: 'Request',
							})}
						/>
						<Button
							icon={sent ? FaCheck : undefined}
							text={sent ? 'Request Sent' : 'Send Request'}
							onClick={sendContactRequest}
							loading={loading}
							disabled={sent || !filledOut}
						/>
					</ContactRequestForm>
				</Page>
			</CollapsableContent>
			<CollapsableContent open={page === 'findUs'}>
				<Page>
					<PageHeader>6464 Savoy Dr Suite 765 Houston, TX 77036</PageHeader>
					<PageContent>
						<Iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3465.1711613401103!2d-95.5059174989661!3d29.71480089363725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c24eaf700003%3A0x13602c5274886812!2s6464%20Savoy%20Dr%20%23765%2C%20Houston%2C%20TX%2077036%2C%20USA!5e0!3m2!1sen!2sch!4v1635421663614!5m2!1sen!2sch"
							loading="lazy"
						></Iframe>
					</PageContent>
				</Page>
			</CollapsableContent>
		</Container>
	)
}

const Container = styled.div`
	background: rgba(0, 0, 0, 0.2);
`

const Banner = styled.div`
	display: grid;
	grid-template-columns: repeat(6, auto);
	justify-content: space-evenly;
	place-items: center;
	@media (max-width: 700px) {
		grid-template-columns: repeat(3, auto);
	}
`

const Link = styled.div`
	height: 40px;
	line-height: 40px;
	font-weight: 300;
	text-decoration: none;
	cursor: pointer;
	opacity: 0.6;
	:hover {
		opacity: 1;
	}
	${({ theme }) => css`
		color: ${theme.colors.BlueStone};
	`}
	@media (max-width: ${MobileMaxWidth}) {
		font-size: 12px;
	}
	@media (max-width: 700px) {
		height: 30px;
		line-height: 30px;
	}
`

const CollapsableContent = styled(Collapsable)`
	display: flex;
	justify-content: center;
	background: ${({ theme }) => theme.colors.Air};
`

const Page = styled.div`
	max-width: 1200px;
	padding: 60px 30px;
	@media (max-width: ${MobileMaxWidth}) {
		padding: 40px 30px;
	}
`

const PageHeader = styled.div`
	margin-bottom: 20px;
	:not(:first-of-type) {
		margin-top: 30px;
	}
	${({ theme }) => css`
		color: ${theme.colors.BlueStone};
	`}
	font-size: 24px;
	line-height: 32px;
	@media (max-width: ${MobileMaxWidth}) {
		font-size: 18px;
		line-height: 26px;
	}
`

const PageContent = styled.div`
	${({ theme }) => css`
		color: ${theme.colors.Stone};
	`}
	font-size: 20px;
	line-height: 30px;
	@media (max-width: ${MobileMaxWidth}) {
		font-size: 15px;
		line-height: 24px;
	}
`

const P = styled.p`
	margin: 10px 0 10px 0;
`

const JobHeader = styled(P)`
	font-size: 1.2em;
	margin-top: 25px;
`

const JobSubHeader = styled(P)`
	font-size: 1.2em;
	font-weight: 300;
`

const JobItem = styled.div`
	margin-left: 20px;
`

const AMail = styled.a`
	color: ${({ theme }) => theme.colors.DarkGold};
`

const Iframe = styled.iframe`
	width: 600px;
	height: 600px;
	max-width: 100%;
	border: none;
`

const ContactRequestForm = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	gap: 20px;
	width: 500px;
	max-width: 100%;
`
