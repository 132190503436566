import { MobileMaxWidth } from 'constants/styles'
import { useLanguage } from 'languages/hooks'
import React from 'react'
import styled, { css } from 'styled-components/macro'
import { useWidth } from 'styling/useWidth'

export const LoginHeading = () => {
	const { translations } = useLanguage()
	const { isMobile } = useWidth()

	return (
		<Container isMobile={isMobile}>
			<HeadingLogo src={`/logo192.png`} />
			<Title>{translations['heading.slogan']}</Title>
			<Labels>
				<div>{translations['heading.title']}</div>
			</Labels>
		</Container>
	)
}
interface ContainerProps {
	isMobile?: boolean
}

const Container = styled.div<ContainerProps>`
	position: relative;
	height: 20vw;
	max-height: 100vh;
	background-position: center;
	background-size: cover;
	${p => {
		const suffix = p.isMobile ? '_mobile' : ''
		return css`
			background-image: url(/images/dashboard_hands${suffix}.jpg);
		`
	}}
	@media (max-width: ${MobileMaxWidth}) {
		height: 450px;
		min-height: 20vw;
		font-size: 17px;
		line-height: 28px;
	}
`

const HeadingLogo = styled.img`
	display: block;
	position: absolute;
	top: 50px;
	left: 60px;
	cursor: pointer;
	z-index: 1;
	max-width: 150px;
	max-height: 150px;
	width: auto;
	height: auto;
	@media (max-width: ${MobileMaxWidth}) {
		top: 20px;
		left: 0;
		right: 0;
		margin: auto;
		max-width: 100px;
		max-height: 100px;
	}
`

const Title = styled.div`
	position: absolute;
	top: calc(50% - 80px);
	width: 100%;
	font-size: 72px;
	line-height: 80px;
	text-align: center;
	color: #fff;
	@media (max-width: ${MobileMaxWidth}) {
		top: 40%;
		font-size: 36px;
		line-height: 42px;
	}
`

const Labels = styled.div`
	position: absolute;
	top: 67%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	font-size: 34px;
	color: #fff;
	@media (max-width: ${MobileMaxWidth}) {
		font-size: 25px;
	}
`
