import { parseString } from 'models/parsing'

export class Credentials {
	username: string = ''
	password: string = ''

	static create(obj: any = {}): Credentials {
		return {
			username: parseString(obj.username),
			password: parseString(obj.password),
		}
	}
}
