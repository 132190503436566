import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { AiOutlineFileProtect } from 'react-icons/ai'
import { Button } from 'components/controls/Button'
import { Section } from 'components/controls/Section'
import { CardSection } from 'components/widgets/CardSection'
import { LandingGrid } from 'components/widgets/LandingGrid'
import { MobileMaxWidth } from 'constants/styles'
import { SubscriptionStatus } from 'models/SubscriptionStatus'
import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { FaInfo } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { useAppContext } from 'store/AppContext'
import styled from 'styled-components/macro'
import { DateFormat, DateTimeFormat } from 'utils/date'

export const UserDashboardMembership = () => {
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState('')
	const [showPaymentForm, setShowPaymentForm] = useState(false)
	const { push } = useHistory()
	const { fetchSubscriptionInfo, subscriptionInfo } = useAppContext()
	const stripe = useStripe()
	const elements = useElements()

	const fetchSub = useCallback(async () => {
		setLoading(true)
		await fetchSubscriptionInfo()
		setLoading(false)
	}, [fetchSubscriptionInfo])

	const periodStart = useMemo(
		() =>
			subscriptionInfo?.currentPeriodStart
				? moment(subscriptionInfo?.currentPeriodStart).format(DateFormat)
				: null,
		[subscriptionInfo]
	)

	const periodEnd = useMemo(
		() =>
			subscriptionInfo?.currentPeriodEnd
				? moment(subscriptionInfo?.currentPeriodEnd).format(DateFormat)
				: null,
		[subscriptionInfo]
	)

	const paymentDue = useMemo(
		() =>
			subscriptionInfo?.currentPeriodEnd
				? moment(subscriptionInfo?.currentPeriodEnd).format(DateTimeFormat)
				: null,
		[subscriptionInfo]
	)

	const updatePaymentMethod = async () => {
		setMessage('')
		if (!stripe || !elements) {
			// TODO: disable form when stripe's not loaded
			return
		}

		const cardElement = elements.getElement(CardElement)
		if (!cardElement) return

		const { paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
		})

		if (!paymentMethod) return

		setLoading(true)
		try {
			const response = await window.fetch('/api/subscription/paymentMethod', {
				method: 'put',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					paymentMethod: paymentMethod.id,
				}),
			})
			if (response.ok) {
				setMessage('Credit Card updated')
				setShowPaymentForm(false)
			} else {
				setMessage('Could not update Credit Card')
			}
		} catch (e) {
		} finally {
			await fetchSub()
			setLoading(false)
		}
	}

	const retryPayment = async () => {
		setLoading(true)
		setMessage('')
		try {
			const response = await window.fetch('/api/subscription/payment', {
				method: 'post',
			})
			if (response.ok) setMessage('Payment successful')
			else setMessage('Payment failed')
		} catch (e) {
		} finally {
			await fetchSub()
			setLoading(false)
		}
	}

	const active = subscriptionInfo?.status === SubscriptionStatus.Active

	const nextPayment =
		moment(subscriptionInfo?.currentPeriodEnd).isAfter() && active
			? paymentDue
			: 'There is no payment scheduled'

	const canRetryPayment = [
		SubscriptionStatus.Past_due,
		SubscriptionStatus.Unpaid,
	].includes(subscriptionInfo?.status ?? SubscriptionStatus.Unknown)

	return (
		<>
			<Section white textCenter>
				<DownloadLink href="/api/account/contract" target="_blank">
					<ContractIcon />
					Download your membership contract here
				</DownloadLink>
			</Section>

			{subscriptionInfo?.paidByTenant === false && (
				<>
					<Section>
						<LandingGrid>
							<Title>
								Your membership is{' '}
								{subscriptionInfo?.status.replace('_', ' ') || 'unknown'}
							</Title>
							<Label>Paid period</Label>
							<Label>
								{periodStart} to {periodEnd}
							</Label>
							<Label>Next payment</Label>
							<Label>{nextPayment}</Label>
							{canRetryPayment && (
								<RetryButton
									text="Retry payment"
									onClick={retryPayment}
									loading={loading}
								/>
							)}
						</LandingGrid>
					</Section>

					<Section white>
						<Title>Edit credit card details and manage your membership</Title>
					</Section>

					{subscriptionInfo?.card && (
						<Section>
							<LandingGrid>
								<Title>Your {subscriptionInfo.card.brand} credit card</Title>
								<Label>Card number</Label>
								<Label>•••• {subscriptionInfo.card.last4}</Label>
								<Label>Valid thru</Label>
								<Label>
									{subscriptionInfo.card.expireMonth}/
									{subscriptionInfo.card.expireYear}
								</Label>
								{message && (
									<Message>
										<InfoIcon /> {message}
									</Message>
								)}
								{!showPaymentForm && (
									<PaymentUpdateButton
										text="Update credit card"
										onClick={() => setShowPaymentForm(true)}
									/>
								)}
								{showPaymentForm && (
									<>
										<PaymentInput white />
										<PaymentUpdateButton
											text="Save credit card"
											onClick={updatePaymentMethod}
											loading={loading}
											primary
										/>
									</>
								)}
							</LandingGrid>
						</Section>
					)}

					{subscriptionInfo?.status !== SubscriptionStatus.Canceled && (
						<Section white>
							<Centered>
								<Button
									text="Edit membership"
									onClick={() => push('/edit-subscription')}
								/>
							</Centered>
						</Section>
					)}
				</>
			)}
		</>
	)
}

const DownloadLink = styled.a`
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	color: ${({ theme }) => theme.colors.BlueStone};
	border-bottom: 3px solid ${({ theme }) => theme.colors.Gold};
	text-decoration: none;
	font-size: 24px;
	:hover {
		color: ${({ theme }) => theme.colors.DarkGold};
	}
`

const ContractIcon = styled(AiOutlineFileProtect)`
	font-size: 60px;
`

const Label = styled.div`
	font-size: 1.5em;
	align-self: center;
	color: ${({ theme }) => theme.colors.Stone};
	@media (max-width: ${MobileMaxWidth}) {
		text-align: center;
	}
`

const Title = styled(Label)`
	grid-column: span 2;
	grid-row: span 2;
	font-size: 2em;
	color: inherit;
	@media (max-width: ${MobileMaxWidth}) {
		grid-column: auto;
		grid-row: auto;
	}
`

const RetryButton = styled(Button)`
	grid-column: span 4;
	justify-self: center;
	@media (max-width: ${MobileMaxWidth}) {
		grid-column: auto;
	}
`

const PaymentInput = styled(CardSection)`
	grid-column: span 4;
	@media (max-width: ${MobileMaxWidth}) {
		grid-column: auto;
	}
`

const PaymentUpdateButton = styled(Button)`
	grid-column: span 4;
	justify-self: center;
	margin-top: 1em;
	@media (max-width: ${MobileMaxWidth}) {
		grid-column: auto;
	}
`

const Message = styled.div`
	grid-column: span 4;
	font-size: 1.3em;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: ${MobileMaxWidth}) {
		grid-column: auto;
	}
`

const InfoIcon = styled(FaInfo)`
	margin-right: 10px;
	color: ${({ theme }) => theme.colors.Sand};
`

const Centered = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`
