import { parseString } from 'models/parsing'

export class LastWillUpdate {
	recipient: string = ''

	static create(obj: any = {}): LastWillUpdate {
		return {
			recipient: parseString(obj.recipient),
		}
	}
}
