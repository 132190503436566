import { Section } from 'components/controls/Section'
import { UserDashboardFrame } from 'components/pages/UserDashboard/UserDashboardFrame'
import React from 'react'
import { GiBookshelf } from 'react-icons/gi'
import styled from 'styled-components/macro'

export const UserDashboardLibrary = () => (
	<UserDashboardFrame
		icon={GiBookshelf}
		introduction="Get educated about life, wellness, family, health and final
		wishes and preparation."
	>
		<Section>
			<Description>Click on the topic you're interested</Description>
			<FileList>
				<File href="/files/library/grief-loss.pdf" target="_blank">
					Grief and loss
				</File>
				<File
					href="/files/library/how-to-scatter-cremated-remains-ashes.pdf"
					target="_blank"
				>
					How to scatter cremated remains ashes
				</File>
				<File href="/files/library/life-trumps-death.pdf" target="_blank">
					Life trumps death
				</File>
				<File
					href="/files/library/meaningful-words-for-funeral-services.pdf"
					target="_blank"
				>
					Meaningful words for funeral services
				</File>
				<File
					href="/files/library/online-memorials-sharing-family-history-and-life-stories-online.pdf"
					target="_blank"
				>
					Online memorials sharing family history and life stories online
				</File>
				<File
					href="/files/library/sympathy-flowers-advice-from-experts.pdf"
					target="_blank"
				>
					Sympathy flowers advice from experts
				</File>
				<File
					href="/files/library/when-you-cannot-attend-a-memorial-service-writing-a-condolence-letter-can-help.pdf"
					target="_blank"
				>
					When you cannot attend a memorial service writing a condolence letter
					can help
				</File>
			</FileList>
		</Section>
	</UserDashboardFrame>
)

const Description = styled.div`
	font-size: 22px;
	text-align: center;
	margin-bottom: 20px;
`

const FileList = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
`

const File = styled.a`
	font-size: 20px;
	color: ${({ theme }) => theme.colors.DarkGold};
	text-decoration: none;
	margin: 10px;
	:hover {
		text-decoration: underline;
	}
`
