import { getHeaders } from 'api'
import { ContactRequest } from 'models/ContactRequest'
import { PasswordRequest } from 'models/PasswordRequest'
import { PasswordReset } from 'models/PasswordReset'
import { ErrorsOf } from 'validation/ErrorsOf'

export const postContactRequest = async (
	request: ContactRequest
): Promise<{
	ok: boolean
	errors?: ErrorsOf<ContactRequest>
}> => {
	try {
		const response = await window.fetch(`/api/contactRequest`, {
			method: 'post',
			body: JSON.stringify(request),
			headers: getHeaders(),
		})
		if (response.ok) {
			return { ok: true }
		} else if (response.status === 422) {
			return {
				ok: false,
				errors: (await response.json())?.errors,
			}
		} else {
			return { ok: false }
		}
	} catch (e) {
		return { ok: false }
	}
}
export const getPrice = async (
	tenant: string,
	birthdate: string | null = null
): Promise<number> => {
	try {
		const response = await window.fetch(
			`/api/signup/price?tenant=${tenant}&birthdate=${birthdate}`
		)
		return await response.json()
	} catch (e) {
		return 0
	}
}

export const getPublishableKey = async (): Promise<string> => {
	try {
		const response = await window.fetch(`/api/stripe/publishableKey`)
		return await response.text()
	} catch (e) {
		return ''
	}
}

export const postPasswordRequest = async (
	request: PasswordRequest
): Promise<boolean> => {
	try {
		const response = await window.fetch(`/api/account/passwordRequest`, {
			method: 'post',
			body: JSON.stringify(request),
			headers: getHeaders(),
		})
		return response.ok
	} catch (e) {
		return false
	}
}

export const postPasswordReset = async (
	reset: PasswordReset
): Promise<{
	ok: boolean
	errors: ErrorsOf<PasswordReset>
	unknownError: boolean
}> => {
	try {
		const response = await window.fetch(`/api/account/passwordReset`, {
			method: 'post',
			body: JSON.stringify(reset),
			headers: getHeaders(),
		})

		let errors = {}
		let unknownError = false
		if (response.status === 422) {
			var json = await response.json()
			errors = json.errors
		} else if (response.status >= 300) {
			unknownError = true
		}

		return { ok: response.ok, errors, unknownError }
	} catch (e) {
		return { ok: false, errors: {}, unknownError: true }
	}
}
