import { MobileMaxWidth } from 'constants/styles'
import React, { ReactNode } from 'react'
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa'
import styled, { css } from 'styled-components/macro'

interface Props {
	checked?: boolean
	text?: ReactNode
	error?: string
	onChange?: (accepted: boolean) => void
	bordered?: boolean
	className?: string
}

export const Checkbox = ({
	checked,
	text,
	error,
	onChange,
	bordered,
	className,
}: Props) => {
	return (
		<Container
			onClick={() => onChange?.(!checked)}
			bordered={bordered}
			className={className}
		>
			{checked ? (
				<CheckedIcon size={24} />
			) : (
				<UncheckedIcon error={!!error} size={24} />
			)}
			<Text>{text}</Text>
			{error && <Error>{error}</Error>}
		</Container>
	)
}

interface ContainerProps {
	bordered?: boolean
}

const Container = styled.div<ContainerProps>`
	width: 100%;
	cursor: pointer;
	${p =>
		p.bordered
			? css`
					@media (max-width: ${MobileMaxWidth}) {
						padding: 1em 0;
						border: 3px solid rgba(130, 145, 160, 0.3);
						line-height: 20px;
						text-align: center;
					}
			  `
			: null}
`

interface UncheckedIconProps {
	error?: boolean
}

const UncheckedIcon = styled(({ error, ...rest }) => (
	<FaRegSquare {...rest} />
))<UncheckedIconProps>`
	margin-right: 0.5em;
	${({ error, theme }) =>
		error
			? css`
					color: ${theme.colors.Error};
			  `
			: css`
					color: ${theme.colors.Stone};
			  `}
`

const CheckedIcon = styled(FaRegCheckSquare)`
	color: ${({ theme }) => theme.colors.DarkSand};
	margin-right: 0.5em;
`

const Text = styled.span`
	vertical-align: super;
`

const Error = styled.div`
	margin-top: 10px;
	font-size: 0.9em;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.Error};
`
