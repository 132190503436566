import { TextInput } from 'components/controls/TextInput'
import { ManagerFrame } from 'components/widgets/ManagerFrame'
import { FuneralClient } from 'models/FuneralClients'
import moment from 'moment'
import React, { useState } from 'react'
import { FaBan, FaCheck } from 'react-icons/fa'
import styled, { css } from 'styled-components/macro'
import { useDebouncedCallback } from 'use-debounce'
import { DateFormat } from 'utils/date'

export const FuneralDirectorDashboardPage = () => {
	const [loading, setLoading] = useState(false)
	const [query, setQuery] = useState('')
	const [clients, setClients] = useState<FuneralClient[]>([])

	const debounceQuery = useDebouncedCallback(async value => {
		setLoading(true)
		const response = await window.fetch(
			`/api/funeralHome/clients?query=${value}`
		)
		const clients = await response.json()
		setClients(clients)
		setLoading(false)
	}, 500)

	const onQueryChange = async (value: string) => {
		setQuery(value)
		if (value.length >= 3) debounceQuery(value)
		else setClients([])
	}

	return (
		<ManagerFrame>
			<SearchInput
				value={query}
				onChange={onQueryChange}
				placeholder="Search Clients..."
				type="search"
				focusOnMount
			/>
			{loading ? (
				<div>Loading...</div>
			) : query.length < 3 ? (
				<div>Type at least 3 characters</div>
			) : clients.length === 0 ? (
				<div>No Results for '{query}'</div>
			) : (
				<Table>
					<thead>
						<tr>
							<th>Active</th>
							<th>First Name</th>
							<th>Last Name</th>
							<th>E-Mail</th>
							<th>SSN</th>
							<th>Address</th>
							<th>City</th>
							<th>ZIP</th>
							<th>Registered</th>
						</tr>
					</thead>
					<tbody>
						{clients.map(client => (
							<Tr active={client.active} key={client.id}>
								<td>{client.active ? <FaCheck /> : <FaBan />}</td>
								<td>{client.firstName}</td>
								<td>{client.lastName}</td>
								<td>{client.email}</td>
								<td>{client.ssn}</td>
								<td>{client.address}</td>
								<td>{client.city}</td>
								<td>{client.zip}</td>
								<td>{moment(client.registered).format(DateFormat)}</td>
							</Tr>
						))}
					</tbody>
				</Table>
			)}
		</ManagerFrame>
	)
}

const SearchInput = styled(TextInput)`
	max-width: 400px;
	margin-bottom: 20px;
`

const Table = styled.table`
	margin-top: 2em;
	text-align: left;
	& td,
	& th {
		padding: 5px;
	}
`

const Tr = styled.tr`
	${({ active }: { active: boolean }) =>
		active
			? null
			: css`
					opacity: 0.5;
					text-decoration: line-through;
					text-decoration-color: #555;
			  `}
`
