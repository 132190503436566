import { parseString } from 'models/parsing'

export class FamilyMember {
	id: string = ''
	firstName: string = ''
	middleName: string = ''
	lastName: string = ''
	ssn: string = ''

	static create(obj: any = {}): FamilyMember {
		return {
			id: parseString(obj.id),
			firstName: parseString(obj.firstName),
			middleName: parseString(obj.middleName),
			lastName: parseString(obj.lastName),
			ssn: parseString(obj.ssn),
		}
	}
}
