import { getHeaders } from 'api'
import { BatchImport } from 'models/BatchImport'
import { CsvImport } from 'models/CsvImport'

export const postBatch = async (
	batchImport: BatchImport
): Promise<BatchImport> => {
	try {
		const response = await window.fetch('/api/batchImports', {
			method: 'POST',
			headers: getHeaders(),
			body: JSON.stringify(batchImport),
		})

		return await response.json()
	} catch (e) {
		return BatchImport.create()
	}
}

export const getBatchImports = async (): Promise<BatchImport[]> => {
	try {
		const response = await window.fetch('/api/batchImports')

		return await response.json()
	} catch (e) {
		return []
	}
}

export const postBatchMembers = async (
	id: string,
	content: string
): Promise<boolean> => {
	try {
		var response = await window.fetch(`/api/batchImports/${id}/members`, {
			method: 'POST',
			headers: getHeaders(),
			body: JSON.stringify(CsvImport.create({ content })),
		})
		return response.ok
	} catch (e) {
		return false
	}
}

export const postBatchMails = async (id: string): Promise<boolean> => {
	try {
		var response = await window.fetch(`/api/batchImports/${id}/mails`, {
			method: 'POST',
			headers: getHeaders(),
		})
		return response.ok
	} catch (e) {
		return false
	}
}
