import { postBatchMails, postBatchMembers } from 'api/batchImports'
import { Button } from 'components/controls/Button'
import { Checkbox } from 'components/controls/Checkbox'
import React, { useState } from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { Link, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

export const GlobalAdminDashboardBatchImportUpload = () => {
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
	const [confirmed, setConfrimed] = useState(false)
	const [csvContent, setCsvContent] = useState('')

	const { id } = useParams<{ id: string }>()

	const upload = async () => {
		setLoading(true)
		const success = await postBatchMembers(id, csvContent)
		setSuccess(success)
		setLoading(false)
	}
	const sendEmails = async () => {
		setLoading(true)
		await postBatchMails(id)
		setLoading(false)
	}

	return (
		<Container>
			<ButtonLink to="/dashboard/batch-imports">
				<Button text="Back" icon={FiArrowLeft} small />
			</ButtonLink>
			<TextArea
				placeholder="Copy CSV content here..."
				onChange={e => setCsvContent(e.target.value)}
				disabled={success}
			/>
			<Checkbox
				text="Yes, I understand that this action will create members."
				checked={confirmed}
				onChange={setConfrimed}
			/>
			<Button
				text="Import"
				onClick={upload}
				loading={loading}
				disabled={!confirmed || success}
			/>
			{success && <Status>Members successfully Imported!</Status>}
			<Button
				text="Send Emails"
				onClick={sendEmails}
				loading={loading}
				disabled={!confirmed}
			/>
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	display: grid;
	row-gap: 20px;
`

const Status = styled.div`
	margin: 20px;
	text-align: center;
	font-size: 20px;
`
const TextArea = styled.textarea`
	${({ theme }) => css`
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		height: 10em;
		min-height: 10em;
		margin-bottom: 50px;
		background: ${theme.colors.Air};
		border: 1px solid ${theme.colors.Stone};
		font-family: 'Courier New', Courier, monospace;
		font-size: 12px;
	`}
`

const ButtonLink = styled(Link)`
	text-decoration: none;
`
