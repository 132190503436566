import { Label } from 'components/controls/Label'
import { ValidationError } from 'components/controls/ValidationError'
import { ChangeEvent, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

interface Props {
	value: string
	onChange?: (value: string) => void
	onBlur?: (value: string) => void
	label?: string
	error?: string
	icon?: ReactNode
	placeholder?: string
	required?: boolean
	readOnly?: boolean
	date?: boolean
	type?: 'password' | 'search' | 'date'
	className?: string
	gray?: boolean
	autoComplete?: string
	upperCase?: boolean
	focusOnMount?: boolean
	rows?: number
}

export const TextInput = ({
	value,
	onChange,
	onBlur,
	label,
	error,
	placeholder,
	required,
	readOnly,
	type,
	className,
	gray,
	autoComplete,
	upperCase,
	focusOnMount,
	rows,
}: Props) => {
	const onValueChanged = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (readOnly) return
		let value = event.currentTarget.value
		if (upperCase) value = value.toUpperCase()
		if (onChange) onChange(value)
	}

	const onInputBlur = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (onBlur) onBlur(event.currentTarget.value)
	}

	return (
		<Container className={className}>
			{label && (
				<Label error={!!error}>
					{label}
					{required && <Required />}
				</Label>
			)}
			{rows ? (
				<StyledTextArea
					rows={rows}
					autoFocus={focusOnMount}
					value={value}
					onChange={onValueChanged}
					onBlur={onInputBlur}
					placeholder={placeholder}
					error={!!error}
					disabled={readOnly}
					gray={gray}
					autoComplete={autoComplete || 'off'}
					spellCheck={false}
					autoCorrect="off"
					autoCapitalize={upperCase ? 'characters' : 'off'}
				/>
			) : (
				<StyledInput
					autoFocus={focusOnMount}
					value={value}
					onChange={onValueChanged}
					onBlur={onInputBlur}
					placeholder={placeholder}
					error={!!error}
					type={type || 'text'}
					disabled={readOnly}
					gray={gray}
					autoComplete={autoComplete || 'off'}
					spellCheck={false}
					autoCorrect="off"
					autoCapitalize={upperCase ? 'characters' : 'off'}
				/>
			)}
			{error && <ValidationError>{error}</ValidationError>}
		</Container>
	)
}

export const Required = styled.span`
	color: ${({ theme }) => theme.colors.Sand};
	:after {
		content: '*';
	}
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

const borderColor = 'rgba(130, 145, 160, 0.4)'
const placeholderColor = '#c5c5df'

interface InputProps {
	error: boolean
	gray?: boolean
	disabled?: boolean
}

const StyledInput = styled.input<InputProps>`
	position: relative;
	height: 45px;
	padding: 0 15px;
	font-size: 18px;
	box-sizing: content-box;
	border: none;
	color: #23323c;

	::placeholder {
		color: ${placeholderColor};
	}

	::-webkit-datetime-edit {
		color: ${placeholderColor};
	}

	:focus {
		border: 3px solid ${borderColor};
		margin: -3px;
	}

	${({ error, theme }) =>
		error
			? css`
					border: 3px solid ${theme.colors.Error};
					margin: -3px;
			  `
			: css`
					:focus {
						border: 3px solid ${borderColor};
						margin: -3px;
					}
			  `}

	${({ gray, theme }) =>
		gray
			? css`
					background-color: ${theme.colors.Air};
			  `
			: css`
					background-color: #fff;
			  `}

	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.6;
			cursor: not-allowed;
		`}
`

const StyledTextArea = styled(StyledInput.withComponent('textarea'))`
	padding-top: 10px;
	padding-bottom: 10px;
	height: auto;
`
