import { getFamilyMembers, putFamilyMembers } from 'api/member'
import { Button } from 'components/controls/Button'
import { Section } from 'components/controls/Section'
import { TextInput } from 'components/controls/TextInput'
import { UserDashboardFrame } from 'components/pages/UserDashboard/UserDashboardFrame'
import { FamilyMember } from 'models/FamilyMember'
import { useEffect, useState } from 'react'
import { BsPeopleFill } from 'react-icons/bs'
import styled from 'styled-components/macro'

export const UserDashboardFamily = () => {
	const [loading, setLoading] = useState(false)
	const [form, setForm] = useState<FamilyMember[]>([])

	useEffect(() => {
		const fetchMembers = async () => {
			const members = await getFamilyMembers()
			for (let i = Math.min(members.length, 5); i < 5; i++)
				members.push(new FamilyMember())

			setForm(members)
		}
		fetchMembers()
	}, [])

	const update =
		(key: keyof FamilyMember, index: number) => (value: string) => {
			setForm(prevState => {
				prevState[index][key] = value
				return [...prevState]
			})
		}

	const save = async () => {
		setLoading(true)
		await putFamilyMembers(form)
		setLoading(false)
	}

	return (
		<UserDashboardFrame
			icon={BsPeopleFill}
			introduction="You and your family deserve to have a dignified and peaceful passing.
			Our simple cremation includes: Removal, temporary storage, cremation
			and return of the remains."
		>
			<Section>
				{form.map((member, i) => (
					<Row key={member.id || i}>
						<RowHeader>Family Member #{i + 1}</RowHeader>
						<TextInput
							label="Firstname"
							value={member.firstName}
							onChange={update('firstName', i)}
						/>
						<TextInput
							label="Middlename"
							value={member.middleName}
							onChange={update('middleName', i)}
						/>
						<TextInput
							label="Lastname"
							value={member.lastName}
							onChange={update('lastName', i)}
						/>
						<TextInput
							label="SSN"
							value={member.ssn}
							onChange={update('ssn', i)}
						/>
					</Row>
				))}
			</Section>
			<SaveButton text="Save" onClick={save} loading={loading} />
		</UserDashboardFrame>
	)
}

const Row = styled.div`
	display: grid;
	row-gap: 20px;
	margin-bottom: 40px;
`

const RowHeader = styled.div`
	margin: 40px 0 10px 0;
	font-size: 24px;
	border-bottom: 2px solid rgba(0, 0, 0, 0.05);
`

const SaveButton = styled(Button)`
	position: fixed;
	bottom: 20px;
	right: 20px;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15);
`
