import { Button } from 'components/controls/Button'
import { H1 } from 'components/controls/H1'
import { Section } from 'components/controls/Section'
import { SubscriptionStatus } from 'models/SubscriptionStatus'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppContext } from 'store/AppContext'
import styled from 'styled-components/macro'

export const EditSubscription = () => {
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState('')
	const [showConfirmation, setShowConfirmation] = useState(false)
	const { push } = useHistory()
	const { fetchSubscriptionInfo, subscriptionInfo } = useAppContext()

	const fetchSub = useCallback(async () => {
		setLoading(true)
		await fetchSubscriptionInfo()
		setLoading(false)
	}, [fetchSubscriptionInfo])

	const unsubscribe = async () => {
		setLoading(true)
		setMessage('')
		try {
			const response = await window.fetch('/api/subscription', {
				method: 'delete',
			})
			if (response.ok) push('/dashboard')
			else setMessage('Unsubscribe failed')
		} catch (e) {
		} finally {
			await fetchSub()
			setLoading(false)
		}
	}

	return (
		<>
			<Section white>
				<Header>
					{loading ? 'Loading your Data' : 'Edit your membership'}
				</Header>
			</Section>
			{subscriptionInfo?.status !== SubscriptionStatus.Canceled && (
				<Section>
					<Centered>
						{message && <span>{message}</span>}
						{showConfirmation ? (
							<Grid>
								<CancelHint>
									Are you really sure you want to cancel the services provided
									by CalmaVia?
								</CancelHint>
								<CancelHint>This can not be undone.</CancelHint>
								<Button
									text="NO, keep services"
									onClick={() => setShowConfirmation(false)}
									primary
								/>
								<Button text="YES, unsubscribe" onClick={unsubscribe} />
							</Grid>
						) : (
							<Grid>
								<Button
									text="Unsubscribe from service*"
									onClick={() => setShowConfirmation(true)}
								/>
								<CancelHint>*This will cancel the contract</CancelHint>
							</Grid>
						)}
					</Centered>
				</Section>
			)}
			<Section white>
				<Centered>
					<Button text="Go back" onClick={() => push('/dashboard')} />
				</Centered>
			</Section>
		</>
	)
}

const Header = styled(H1)`
	margin: 0;
`

const Centered = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 40px;
	text-align: center;
`

const CancelHint = styled.div`
	font-size: 22px;
	color: ${({ theme }) => theme.colors.Error};
`
