import { parseString } from 'models/parsing'

export class Broker {
	alias: string = ''
	description: string = ''

	static create(obj: any = {}): Broker {
		return {
			alias: parseString(obj.alias),
			description: parseString(obj.description),
		}
	}
}
