import { Button } from 'components/controls/Button'
import { LanguageSelection } from 'components/controls/LanguageSelection'
import { MobileMaxWidth } from 'constants/styles'
import { DefaultTenant } from 'helpers/tenant'
import { useLanguage } from 'languages/hooks'
import { useEffect, useRef } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { useAppContext } from 'store/AppContext'
import styled, { css } from 'styled-components/macro'
import { useWidth } from 'styling/useWidth'

interface Props {
	altImage?: boolean
	hideLogin?: boolean
	autoScroll?: boolean
}

export const Heading = ({ altImage, hideLogin, autoScroll }: Props) => {
	const { tenant } = useAppContext()

	const { language, setLanguage, translations } = useLanguage()
	const history = useHistory()
	const containerRef = useRef<HTMLDivElement>(null)
	const { isMobile } = useWidth()

	const scrollPastHeading = () => {
		if (containerRef.current)
			window.scrollTo(0, containerRef.current.offsetHeight)
	}

	useEffect(() => {
		if (autoScroll) scrollPastHeading()
	}, [autoScroll])

	const isDefaultTenant = tenant === DefaultTenant

	return (
		<Container
			isMobile={isMobile}
			isDefaultTenant={isDefaultTenant}
			altImage={altImage}
			ref={containerRef}
		>
			<HeadingLogo src={`/tenants/${tenant}/logo.png`} onClick={gotoHome} />
			<Languages language={language} setLanguage={setLanguage} />
			{!isDefaultTenant && (
				<HeadingLogo right src={`/tenants/${DefaultTenant}/logo.png`} />
			)}
			{!hideLogin &&
				(isDefaultTenant ? (
					<LoginButton
						text={translations['heading.login']}
						onClick={() => history.push('/dashboard')}
					/>
				) : (
					<LoginLink onClick={() => history.push('/dashboard')}>
						{translations['heading.login']}
					</LoginLink>
				))}
			<Title small={isDefaultTenant}>{translations['heading.slogan']}</Title>
			<Labels>{translations['heading.title']}</Labels>
			<SubTitle>{translations['heading.subTitle']}</SubTitle>
			<ScrollButton onClick={scrollPastHeading} />
		</Container>
	)
}

const gotoHome = () => {
	window.location.href = '/'
}

interface ContainerProps {
	altImage?: boolean
	isMobile?: boolean
	isDefaultTenant: boolean
}

const Container = styled.div<ContainerProps>`
	position: relative;
	height: 80vw;
	max-height: ${({ isDefaultTenant }) =>
		isDefaultTenant ? 'calc(75vh - 40px)' : '75vh'};
	background-position: center;
	background-size: cover;
	${p => {
		const suffix = p.isMobile ? '_mobile' : ''
		return p.altImage
			? css`
					background-image: url(/images/heading_family_sea${suffix}.jpg);
			  `
			: css`
					background-image: url(/images/heading_family_walking${suffix}.jpg);
			  `
	}}
	@media (max-width: ${MobileMaxWidth}) {
		height: 450px;
		min-height: 80vw;
		font-size: 17px;
		line-height: 28px;
	}
`

const topPadding = '50px'

const HeadingLogo = styled.img<{ right?: boolean }>`
	display: block;
	position: absolute;
	top: 30px;
	cursor: pointer;
	z-index: 1;
	max-width: 150px;
	max-height: 150px;
	width: auto;
	height: auto;
	${({ right }) =>
		right
			? css`
					right: 150px;
			  `
			: css`
					left: 150px;
			  `}
	@media (max-width: ${MobileMaxWidth}) {
		top: 28px;
		max-width: 100px;
		max-height: 100px;
		${({ right }) =>
			right
				? css`
						right: 20px;
				  `
				: css`
						left: 20px;
				  `}
	}
	@media (max-width: 600px) {
		max-width: 60px;
		max-height: 60px;
	}
`

const Languages = styled(LanguageSelection)`
	position: absolute;
	top: ${topPadding};
	width: 100%;
	text-align: center;
	@media (max-width: ${MobileMaxWidth}) {
		top: 30px;
	}
`

const LoginButton = styled(Button)`
	position: absolute;
	top: ${topPadding};
	z-index: 1;
	right: 150px;

	@media (max-width: ${MobileMaxWidth}) {
		top: 28px;
		height: 35px;
		padding: 0 10px;
		font-size: 14px;
		right: 20px;
	}
	@media (max-width: 500px) {
		font-size: 12px;
	}
`

const LoginLink = styled.div`
	display: inline-block;
	position: absolute;
	top: 90px;
	left: 50%;
	transform: translate(-50%, 0);
	padding: 5px 12px;
	font-size: 16px;
	background: rgba(0, 0, 0, 0.1);
	color: white;
	cursor: pointer;
	:hover {
		background: rgba(255, 255, 255, 0.1);
	}
	@media (max-width: ${MobileMaxWidth}) {
		top: 65px;
		padding: 0 8px;
		font-size: 12px;
	}
`

const Title = styled.div<{ small: boolean }>`
	position: absolute;
	top: calc(50% - 80px);
	width: 100%;
	text-align: center;
	color: #fff;
	line-height: 70px;
	font-size: 60px;
	padding: 10px;
	${({ small }) =>
		small
			? css`
					@media (max-width: 800px) {
						top: 40%;
						font-size: 24px;
						line-height: 28px;
					}
			  `
			: css`
					@media (max-width: ${MobileMaxWidth}) {
						top: 40%;
						font-size: 30px;
						line-height: 36px;
					}
			  `}
`

const Labels = styled.div`
	position: absolute;
	top: 63%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0 10px;
	width: 100%;
	font-size: 34px;
	line-height: 1.2em;
	color: #fff;
	@media (max-width: 800px) {
		font-size: 20px;
	}
`

const SubTitle = styled.div`
	position: absolute;
	bottom: 65px;
	width: 100%;
	text-align: center;
	font-size: 24px;
	line-height: 38px;
	color: #fff;
	@media (max-width: ${MobileMaxWidth}) {
		bottom: 50px;
		font-size: 17px;
		line-height: 28px;
	}
`

const ScrollButton = styled(FiChevronDown)`
	position: absolute;
	bottom: 30px;
	left: 0;
	width: 100%;
	text-align: center;
	font-size: 40px;
	color: ${({ theme }) => theme.colors.Sand};
	cursor: pointer;
	transition: color 0.1s ease;
	:hover {
		color: ${({ theme }) => theme.colors.Gold};
	}
	@media (max-width: ${MobileMaxWidth}) {
		bottom: 10px;
	}
`
