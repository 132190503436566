import { parseString } from 'models/parsing'

export class ContactRequest {
	fromEmail: string = ''
	name: string = ''
	subject: string = ''
	request: string = ''

	static create(obj: any = {}): ContactRequest {
		return {
			fromEmail: parseString(obj.fromEmail),
			name: parseString(obj.name),
			subject: parseString(obj.subject),
			request: parseString(obj.request),
		}
	}
}
