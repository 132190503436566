import { Section } from 'components/controls/Section'
import React from 'react'
import { ReactNode } from 'react'
import { IconType } from 'react-icons/lib'
import styled from 'styled-components/macro'

interface Props {
	children?: ReactNode
	introduction?: string
	text?: string
	icon?: IconType
}

export const UserDashboardFrame = ({
	children,
	introduction,
	text,
	icon: Icon,
}: Props) => (
	<>
		{introduction && (
			<Header white>
				{Icon && <Icon size={60} style={{ marginBottom: 20 }} />}
				<div>{introduction}</div>
				{text && <Text>{text}</Text>}
			</Header>
		)}
		{children ?? (
			<ComingSoon white={!introduction}>
				We are currently improving the member area. This section will appear
				soon.
			</ComingSoon>
		)}
	</>
)

const Header = styled(Section)`
	font-size: 24px;
	text-align: center;
`

const Text = styled.div`
	margin-top: 30px;
	font-size: 20px;
`

const ComingSoon = styled(Header)`
	font-size: 20px;
`
