import { parseString } from 'models/parsing'

export class PasswordRequest {
	email: string = ''

	static create(obj: any = {}): PasswordRequest {
		return {
			email: parseString(obj.email),
		}
	}
}
