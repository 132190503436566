import { parseString } from 'models/parsing'

export class PasswordReset {
	email: string = ''
	token: string = ''
	newPassword: string = ''

	static create(obj: any = {}): PasswordReset {
		return {
			email: parseString(obj.email),
			token: parseString(obj.token),
			newPassword: parseString(obj.newPassword),
		}
	}
}
