import React, { Component } from 'react'
import styled from 'styled-components'

interface State {
	hasError: boolean
}

export class ErrorBoundary extends Component<{}, State> {
	constructor(props: {}) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(error: any) {
		console.log(error)
		return { hasError: true }
	}

	render() {
		if (this.state.hasError)
			return (
				<Container>
					<h1>Oops! Something unexpected went wrong</h1>
					<h2>Please refresh the page and try again.</h2>
				</Container>
			)

		return this.props.children
	}
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin: 1em;
`
