import { FamilyMember } from 'models/FamilyMember'
import { parseString } from 'models/parsing'

export class ServiceDetails {
	contractId: string = ''
	familyMembers: FamilyMember[] = []

	static create(obj: any = {}): ServiceDetails {
		return {
			contractId: parseString(obj.contractId),
			familyMembers: obj.familyMembers.map(FamilyMember.create),
		}
	}
}
