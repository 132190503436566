import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

interface Props {
	onClick?: () => void
	text: ReactNode
	loading?: boolean
	icon?: React.ElementType
	disabled?: boolean
	className?: string
	small?: boolean
	primary?: boolean
}

export const Button = ({
	onClick,
	text,
	loading,
	icon: Icon,
	disabled,
	className,
	small,
	primary,
}: Props) => (
	<StyledButton
		className={className}
		onClick={onClick}
		disabled={disabled || loading}
		small={small}
		primary={primary}
	>
		{Icon && <Icon style={{ fontSize: 24, marginRight: 10 }} />}
		{loading ? 'Loading' : text}
	</StyledButton>
)

interface ButtonProps {
	disabled?: boolean
	small?: boolean
	primary?: boolean
}

const StyledButton = styled.button<ButtonProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	line-height: 40px;
	color: #fff;
	border: none;
	transition: background-color 0.1s ease;

	${p =>
		p.small
			? css`
					height: 45px;
					padding: 0 20px;
			  `
			: css`
					padding: 6px 30px;
			  `}

	${p =>
		p.primary
			? css`
					background: ${p.disabled
						? p.theme.colors.Stone
						: p.theme.colors.Gold};
					:hover {
						background: ${p.disabled
							? p.theme.colors.Stone
							: p.theme.colors.DarkGold};
					}
			  `
			: css`
					background: ${p.disabled
						? p.theme.colors.Stone
						: p.theme.colors.Sand};
					:hover {
						background: ${p.disabled
							? p.theme.colors.Stone
							: p.theme.colors.DarkSand};
					}
			  `}
				
		${p =>
		p.disabled
			? css`
					cursor: not-allowed;
			  `
			: css`
					cursor: pointer;
			  `}
`
