import { Button } from 'components/controls/Button'
import { H1 } from 'components/controls/H1'
import { TextInput } from 'components/controls/TextInput'
import { LoginHeading } from 'components/widgets/LoginHeading'
import { useForm } from 'form/helpers'
import { Credentials } from 'models/Credentials'
import React, { FormEvent, useState } from 'react'
import { FiLogIn } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { useAppContext } from 'store/AppContext'
import styled from 'styled-components/macro'

export const LoginPage = () => {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState('')
	const [form, setForm] = useState<Credentials>(Credentials.create())
	const { signIn } = useAppContext()

	const propsFor = useForm(form, setForm, {})

	const login = async () => {
		setLoading(true)
		setError('')
		const { status, error } = await signIn(form.username, form.password)
		setLoading(false)

		if (status < 300) return
		if (status === 401) {
			setError(error || 'Not Authorized')
			return
		}
		setError('Unknown error occured')
	}

	const formLogin = (event: FormEvent) => {
		event.preventDefault()
		login()
	}

	return (
		<form onSubmit={formLogin}>
			<LoginHeading />
			<Container>
				<H1>Sign In</H1>
				<TextInput
					{...propsFor({ id: 'username', label: 'E-Mail' })}
					autoComplete="email"
					required
				/>
				<TextInput
					{...propsFor({ id: 'password', label: 'Password' })}
					type="password"
					autoComplete="current-password"
					required
				/>
				{error && <Error>{error}</Error>}
				<LoginButton
					text="Login"
					icon={FiLogIn}
					onClick={login}
					loading={loading}
					primary
				/>
				<ForgotLink to="/password-request">Forgot Password?</ForgotLink>
			</Container>
		</form>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 30px;
	color: ${({ theme }) => theme.colors.DarkSand};
	margin: 1em 2em;
	& > * {
		max-width: 400px;
		margin-bottom: 1em;
	}
`

const Error = styled.div`
	color: ${({ theme }) => theme.colors.Error};
`

const LoginButton = styled(Button)`
	margin-top: 1em;
`

const ForgotLink = styled(Link)`
	text-decoration: none;
	color: ${({ theme }) => theme.colors.Stone};
	:hover {
		text-decoration: underline;
	}
`
