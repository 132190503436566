import { App } from 'components/App'
import { ErrorBoundary } from 'components/widgets/ErrorBoundary'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import 'react-datepicker/dist/react-datepicker.css'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
	<ErrorBoundary>
		<App />
	</ErrorBoundary>,
	document.getElementById('root')
)

serviceWorker.unregister()
