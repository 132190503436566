import { useMeasure } from 'helpers/useMeasure'
import { usePrevious } from 'helpers/usePrevious'
import { ReactNode } from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components/macro'

interface Props {
	children: ReactNode
	open: boolean
	className?: string
}

export const Collapsable = ({ children, open, className }: Props) => {
	const previouslyOpen = usePrevious(open)
	const [ref, { height: contentHeight }] = useMeasure<HTMLDivElement>()

	const { height, opacity, ...paddings } = useSpring({
		height: open ? contentHeight : 0,
		opacity: open ? 1 : 0,
	})

	return (
		<div className={className}>
			<Wrapper
				style={{
					height: open && previouslyOpen === open ? 'auto' : height,
					...paddings,
				}}
			>
				<animated.div ref={ref} style={{ opacity }} children={children} />
			</Wrapper>
		</div>
	)
}

const Wrapper = styled(animated.div)`
	overflow: hidden;
`
