import { Responsive } from 'components/controls/Responsive'
import { Language } from 'languages/Language'
import React from 'react'
import styled, { css } from 'styled-components/macro'

interface Props {
	language: Language
	setLanguage: (language: Language) => void
	className?: string
}

export const LanguageSelection = ({
	language,
	setLanguage,
	className,
}: Props) => (
	<Container className={className}>
		<LangButton active={language === 'en'} onClick={() => setLanguage('en')}>
			<Responsive desktop="English" mobile="EN" />
		</LangButton>
		<span> / </span>
		<LangButton active={language === 'es'} onClick={() => setLanguage('es')}>
			<Responsive desktop="Español" mobile="ES" />
		</LangButton>
	</Container>
)

const Container = styled.div`
	color: #c0cad4;
	font-size: 18px;
	line-height: 32px;
`

const LangButton = styled.span`
	cursor: pointer;
	transition: color 0.1s ease;

	:hover {
		color: #fff;
	}

	${({ active }: { active: boolean }) =>
		active
			? css`
					color: #fff;
			  `
			: null}
`
