export const parseString = (value: any): string => {
	if (typeof value !== 'string') return ''
	return value || ''
}

export const parseNumber = (value: any): number => {
	if (typeof value !== 'number') return 0
	return value || 0
}

export const parseBoolean = (value: any): boolean => {
	if (typeof value !== 'boolean') return false
	return !!value
}
