import { getPrice } from 'api/public'
import { Checkbox } from 'components/controls/Checkbox'
import { H1 } from 'components/controls/H1'
import { Label } from 'components/controls/Label'
import { Responsive } from 'components/controls/Responsive'
import { MobileLeftRightPadding } from 'components/controls/Section'
import { TextInput } from 'components/controls/TextInput'
import { ValidationError } from 'components/controls/ValidationError'
import { LandingGrid } from 'components/widgets/LandingGrid'
import { MobileMaxWidth } from 'constants/styles'
import { useForm } from 'form/helpers'
import { formatPrice } from 'helpers/money'
import { useLanguage } from 'languages/hooks'
import { Language } from 'languages/Language'
import { Beneficiary } from 'models/Beneficiary'
import { SignupDetails } from 'models/SignupDetails'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Select from 'react-select'
import { useAppContext } from 'store/AppContext'
import styled from 'styled-components/macro'
import { ErrorsOf } from 'validation/ErrorsOf'

interface Props {
	form: SignupDetails
	setForm: Dispatch<SetStateAction<SignupDetails>>
	errors: ErrorsOf<SignupDetails>
}

export const SignupContactDetails = ({ form, setForm, errors }: Props) => {
	const {
		tenant,
		broker,
		availableBrokers,
		basePrice,
		features: { hasDonation, hasPromoCode, hasBrokerChoice },
		setBroker,
	} = useAppContext()

	const { translations, language } = useLanguage()
	const [price, setPrice] = useState(basePrice)
	const [donation, setDonation] = useState<number>()

	useEffect(() => {
		if (hasDonation) setDonation(200)
	}, [hasDonation])

	useEffect(() => {
		if (price === 0 && basePrice !== 0) setPrice(basePrice)
	}, [basePrice, price])

	useEffect(() => {
		setForm(form => ({ ...form, donation: donation ?? 0 }))
	}, [donation, setForm])

	const propsFor = useForm(form, setForm, errors, 'user')

	const beneficiaryPropsFor = (beneficiary: Beneficiary, text: string) => {
		return {
			text,
			checked: form.beneficiary === beneficiary,
			onChange: () =>
				setForm(prevState => ({
					...prevState,
					beneficiary: beneficiary,
				})),
		}
	}

	const checkBirthdateFormat =
		(field: keyof SignupDetails) => (birthdate: string) => {
			const matches = birthdate.match(/^(\d{1,2}\/\d{1,2}\/)(\d{2})$/)
			if (matches?.length === 3) {
				const century = Number(matches[2]) < 20 ? '20' : '19'
				const fixedBirthdate = `${matches[1]}${century}${matches[2]}`
				setForm(state => ({ ...state, [field]: fixedBirthdate }))
				return fixedBirthdate
			}
			return birthdate
		}

	const calculatePrice = async (birthdate: string) => {
		const checkedBirthdate = checkBirthdateFormat('birthdate')(birthdate)
		const calculatedPrice = await getPrice(tenant, checkedBirthdate)
		setPrice(calculatedPrice)
	}

	const extendedForm = form.beneficiary !== Beneficiary.Me
	const formRows =
		(extendedForm ? 13 : 6) + (hasDonation ? 1 : 0) + (hasBrokerChoice ? 1 : 0)

	return (
		<>
			<H1>{translations['signupContact.title']}</H1>
			<LandingGrid>
				<BeneficiaryForm rows={formRows}>
					<BeneficiaryChoices>
						<Checkbox
							{...beneficiaryPropsFor(
								Beneficiary.Me,
								translations['signupContact.subscription.myself']
							)}
						/>
						<Checkbox
							{...beneficiaryPropsFor(
								Beneficiary.Parents,
								translations['signupContact.subscription.parents']
							)}
						/>
					</BeneficiaryChoices>
				</BeneficiaryForm>
				{extendedForm && (
					<FormHeader>
						{translations['signupContact.subscription.aboutBeneficiary']}
					</FormHeader>
				)}
				{hasDonation && (
					<>
						<StretchedInput>
							<Label>{translations['user.donation']}</Label>
							<Select
								placeholder="-"
								value={getDonationItem(language, donation)}
								onChange={d => setDonation(d?.value)}
								options={[
									getDonationItem(language),
									getDonationItem(language, 200),
									getDonationItem(language, 400),
									getDonationItem(language, 1000),
									getDonationItem(language, 2000),
								]}
								styles={{
									control: () => ({
										display: 'flex',
										border: 'none',
										height: 45,
										background: '#fff',
									}),
								}}
							/>
						</StretchedInput>
						<Responsive desktop={<div />} />
					</>
				)}
				{hasBrokerChoice && (
					<>
						<StretchedInput>
							<Select
								placeholder="-"
								value={{ value: broker?.alias, label: broker?.description }}
								onChange={b =>
									b &&
									setBroker({
										alias: b.value ?? '',
										description: b.label ?? '',
									})
								}
								options={availableBrokers.map(b => ({
									value: b.alias,
									label: b.description,
								}))}
								styles={{
									control: () => ({
										display: 'flex',
										border: 'none',
										height: 45,
										background: '#fff',
									}),
								}}
							/>
						</StretchedInput>
						<Responsive desktop={<div />} />
					</>
				)}
				<TextInput
					{...propsFor({
						id: 'birthdate',
						placeholder: '12/31/1965',
					})}
					autoComplete="bday"
					onBlur={calculatePrice}
					required
				/>
				{hasPromoCode ? (
					<TextInput
						{...propsFor({
							id: 'promoCode',
						})}
					/>
				) : (
					<Responsive desktop={<div />} />
				)}
				<Responsive desktop={<div />} />
				<TextInput
					{...propsFor({
						id: 'firstName',
						placeholder: 'Elliot',
					})}
					autoComplete="given-name"
					required
				/>
				<TextInput
					{...propsFor({
						id: 'middleName',
						placeholder: 'Mary',
					})}
					autoComplete="additional-name"
				/>
				<TextInput
					{...propsFor({
						id: 'lastName',
						placeholder: 'Smith',
					})}
					autoComplete="family-name"
					required
				/>
				<TextInput
					{...propsFor({
						id: 'address',
						placeholder: 'Street Address',
					})}
					autoComplete="street-address"
					required
				/>
				<TextInput
					{...propsFor({
						id: 'address2',
						placeholder: 'Apartment, Suite, Unit etc',
					})}
				/>
				<Responsive desktop={<div />} />
				<TextInput
					{...propsFor({
						id: 'city',
						placeholder: 'Houston',
					})}
					autoComplete="address-level2"
					required
				/>
				<TextInput
					{...propsFor({
						id: 'state',
						placeholder: 'TX',
					})}
					autoComplete="address-level1"
					required
					upperCase
				/>
				<TextInput
					{...propsFor({
						id: 'zip',
						placeholder: '12345 or 12345-6789',
					})}
					autoComplete="postal-code"
					required
				/>
				{form.beneficiary === Beneficiary.Me && (
					<TextInput
						{...propsFor({
							id: 'email',
							placeholder: 'elliot.smith@gmail.com',
						})}
						autoComplete="email"
						required
					/>
				)}
				<TextInput
					{...propsFor({
						id: 'phone',
						placeholder: '555-123-4567',
					})}
					autoComplete="tel-national"
					required
				/>
				<Responsive desktop={<div />} />
				{extendedForm && (
					<>
						<FormHeader>
							{translations['signupContact.subscription.aboutYourself']}
						</FormHeader>
						<TextInput
							{...propsFor({
								id: 'purchaserBirthdate',
								placeholder: '12/31/1965',
							})}
							autoComplete="bday"
							onBlur={checkBirthdateFormat('purchaserBirthdate')}
							required
						/>
						<Responsive desktop={<div />} />
						<Responsive desktop={<div />} />
						<TextInput
							{...propsFor({
								id: 'purchaserFirstName',
								placeholder: 'Elliot',
							})}
							autoComplete="given-name"
							required
						/>
						<TextInput
							{...propsFor({
								id: 'purchaserMiddleName',
								placeholder: 'Mary',
							})}
							autoComplete="additional-name"
						/>
						<TextInput
							{...propsFor({
								id: 'purchaserLastName',
								placeholder: 'Smith',
							})}
							autoComplete="family-name"
							required
						/>
						<TextInput
							{...propsFor({
								id: 'purchaserAddress',
								placeholder: 'Street Address',
							})}
							autoComplete="street-address"
							required
						/>
						<TextInput
							{...propsFor({
								id: 'purchaserAddress2',
								placeholder: 'Apartment, Suite, Unit etc',
							})}
						/>
						<Responsive desktop={<div />} />
						<TextInput
							{...propsFor({
								id: 'purchaserCity',
								placeholder: 'Houston',
							})}
							autoComplete="address-level2"
							required
						/>
						<TextInput
							{...propsFor({
								id: 'purchaserState',
								placeholder: 'TX',
							})}
							autoComplete="address-level1"
							required
							upperCase
						/>
						<TextInput
							{...propsFor({
								id: 'purchaserZip',
								placeholder: '12345 or 12345-6789',
							})}
							autoComplete="postal-code"
							required
						/>
						<TextInput
							{...propsFor({
								id: 'email',
								placeholder: 'elliot.smith@gmail.com',
							})}
							autoComplete="email"
							required
						/>
						<TextInput
							{...propsFor({
								id: 'purchaserPhone',
								placeholder: '555-123-4567',
							})}
							autoComplete="tel-national"
							required
						/>
						<div />
					</>
				)}
				<PriceCalculation rows={formRows}>
					{translations['signupContact.subscription.calculatedPrice1']}
					<br />
					{translations['signupContact.subscription.calculatedPrice2']}
					<Amount>
						${formatPrice(price)}
						<Donation>
							{donation
								? ` + $${formatPrice(donation)} ${translations[
										'user.donation'
								  ].toLowerCase()}`
								: ''}
						</Donation>
					</Amount>
				</PriceCalculation>
				<Faq>
					<FaqLink href={`/files/FAQ.${language}.pdf`} target="_blank">
						{translations['signupContact.subscription.faqTitle']}
					</FaqLink>
				</Faq>
				<Responsive
					desktop={
						<AgreementDisclosure>
							{translations['signupContact.subscription.disclosureAgreement']}
							<AgreementNote>
								<AgreementLink
									href="/files/TermsAndConditions.html"
									target="_blank"
								>
									{translations['general.readMore']}
								</AgreementLink>
							</AgreementNote>
						</AgreementDisclosure>
					}
				/>
				<Footer>
					<Checkbox
						bordered
						checked={form.termsAndConditionsAgreed}
						onChange={accepted => {
							setForm({ ...form, termsAndConditionsAgreed: accepted })
						}}
						text={translations['signupContact.subscription.acceptTerms']}
					/>
					{errors.termsAndConditionsAgreed && (
						<ValidationError>{errors.termsAndConditionsAgreed}</ValidationError>
					)}
				</Footer>
				<Responsive
					mobile={
						<AgreementLink
							href="/files/TermsAndConditions.html"
							target="_blank"
						>
							{translations['signupContact.subscription.disclosureAgreement']}
						</AgreementLink>
					}
				/>
			</LandingGrid>
		</>
	)
}

const getDonationItem = (language: Language, value?: number) =>
	[
		{
			value: 200,
			label:
				language === 'es' ? 'Donación mensual de $2' : '$2 monthly donation',
		},
		{
			value: 400,
			label:
				language === 'es' ? 'Donación mensual de $4' : '$4 monthly donation',
		},
		{
			value: 1000,
			label:
				language === 'es' ? 'Donación mensual de $10' : '$10 monthly donation',
		},
		{
			value: 2000,
			label:
				language === 'es' ? 'Donación mensual de $20' : '$20 monthly donation',
		},
	].find(i => i.value === value) ?? {
		value: undefined,
		label: '-',
	}

const StretchedInput = styled.div`
	grid-column: 2 / -2;
`

const FormHeader = styled.div`
	grid-column: span 3;
	font-size: 24px;
	line-height: 38px;
	@media (max-width: ${MobileMaxWidth}) {
		grid-column: auto;
		margin: 1.5em 0;
		font-size: 17px;
		line-height: 28px;
		text-align: center;
	}
`

interface RowsProps {
	rows: number
}

const BeneficiaryForm = styled.div<RowsProps>`
	grid-row: 1 / ${p => p.rows - 2};
	font-size: 24px;
	line-height: 38px;
	@media (max-width: ${MobileMaxWidth}) {
		grid-row: auto;
		width: 100vw;
		margin-left: -${MobileLeftRightPadding};
		margin-right: -${MobileLeftRightPadding};
		padding: ${MobileLeftRightPadding};
		background: #fff;
	}
`

const PriceCalculation = styled.div<RowsProps>`
	grid-row: ${p => p.rows - 2} / ${p => p.rows};
	font-size: 24px;
	line-height: 38px;
	align-self: end;
	@media (max-width: ${MobileMaxWidth}) {
		grid-row: auto;
		margin-top: 2em;
		text-align: center;
		line-height: 32px;
	}
`

const Amount = styled.div`
	font-size: 36px;
	line-height: 50px;
	color: ${({ theme }) => theme.colors.Sand};
	@media (max-width: ${MobileMaxWidth}) {
		margin-top: 14px;
		line-height: 40px;
	}
`

const Donation = styled.div`
	font-size: 24px;
`

const BeneficiaryChoices = styled.div`
	font-size: 18px;
	line-height: 31px;
	@media (max-width: ${MobileMaxWidth}) {
		font-size: 16px;
	}
`

const Footer = styled.div`
	margin-top: 65px;
	padding-top: 25px;
	border-top: 2px solid ${({ theme }) => theme.colors.Sand};
	@media (max-width: ${MobileMaxWidth}) {
		margin-top: 10px;
		border: none;
		font-size: 17px;
		line-height: 28px;
		text-align: center;
	}
`

const Faq = styled(Footer)`
	font-size: 16px;
	@media (max-width: ${MobileMaxWidth}) {
		border-top: 2px solid ${({ theme }) => theme.colors.Sand};
	}
`

const FaqLink = styled.a`
	display: inline;
	color: ${({ theme }) => theme.colors.Sand};
	text-decoration: none;
	:hover {
		color: ${({ theme }) => theme.colors.DarkSand};
		border-color: ${({ theme }) => theme.colors.DarkSand};
	}
	@media (max-width: ${MobileMaxWidth}) {
		border: none;
	}
`

const AgreementDisclosure = styled(Footer)`
	grid-column: span 2;
	font-size: 16px;
	line-height: 25px;
	@media (max-width: ${MobileMaxWidth}) {
		grid-column: auto;
	}
`

const AgreementNote = styled.div`
	color: ${({ theme }) => theme.colors.Stone};
`

const AgreementLink = styled.a`
	display: inline;
	color: ${({ theme }) => theme.colors.Stone};
	border-bottom: 1px solid ${({ theme }) => theme.colors.Stone};
	text-decoration: none;
	:hover {
		color: ${({ theme }) => theme.colors.DarkSand};
		border-bottom-color: ${({ theme }) => theme.colors.DarkSand};
	}
	@media (max-width: ${MobileMaxWidth}) {
		font-size: 14px;
		border: none;
		text-decoration: underline;
		text-align: center;
	}
`
