import { LoginPage } from 'components/pages/LoginPage'
import React from 'react'
import { Route, RouteProps } from 'react-router'
import { useAppContext } from 'store/AppContext'

export const ProtectedRoute = (props: RouteProps) => {
	const { authenticated } = useAppContext()

	return authenticated ? <Route {...props} /> : <LoginPage />
}
