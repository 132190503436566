import { MobileMaxWidth } from 'constants/styles'
import styled, { css } from 'styled-components/macro'

interface Props {
	error?: boolean
}

export const Label = styled.label<Props>`
	font-size: 22px;
	line-height: 36px;

	${({ error, theme }) =>
		error
			? css`
					color: ${theme.colors.Error};
			  `
			: css`
					color: ${theme.colors.Stone};
			  `}
	@media(max-width: ${MobileMaxWidth}) {
		margin-bottom: 5px;
		font-size: 17px;
		line-height: 28px;
	}
`
